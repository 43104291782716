import React, { useEffect } from 'react';
import { func } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { tokenReceived } from './store/actions';

import FullPageLoading from '../components/FullPageLoading';

export function Page({ onTokenReceived }) {
  const { search } = useLocation();

  useEffect(() => {
    const token = getTokenFromQueryParams(search);
    onTokenReceived(token);
  }, [search, onTokenReceived]);

  return <FullPageLoading />;
}
Page.propTypes = {
  onTokenReceived: func,
};
Page.defaultProps = {
  onTokenReceived: (_token) => {},
};

const getTokenFromQueryParams = (search) => {
  const searchParams = new URLSearchParams(search);
  return searchParams.get('token');
};

const mapDispatchToProps = (dispatch) => ({
  onTokenReceived: (token) => dispatch(tokenReceived({ token })),
});
export default connect(undefined, mapDispatchToProps)(Page);
