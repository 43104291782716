import {
  CHECK,
  CLEAR_DATA,
  CLEAR_REDIRECT_PATH,
  CLEAR_TOKEN,
  CLEAR_TOKEN_DATA,
  CLEAR_USER_DATA,
  FETCH_USER_DATA,
  SET_REDIRECT_PATH,
  SET_TOKEN,
  SET_TOKEN_DATA,
  SET_USER_DATA,
  SET_USER_DATA_LOADING,
  TOKEN_RECEIVED,
} from './actionTypes';

export const check = ({ location }) => ({
  type: CHECK,
  payload: { location },
});

export const clearData = () => ({
  type: CLEAR_DATA,
});

export const clearRedirectPath = () => ({
  type: CLEAR_REDIRECT_PATH,
});

export const clearToken = () => ({
  type: CLEAR_TOKEN,
});

export const clearTokenData = () => ({
  type: CLEAR_TOKEN_DATA,
});

export const clearUserData = () => ({
  type: CLEAR_USER_DATA,
});

export const fetchUserData = () => ({
  type: FETCH_USER_DATA,
});

export const tokenReceived = ({ token }) => ({
  type: TOKEN_RECEIVED,
  payload: { token },
});

export const setRedirectPath = ({ path }) => ({
  type: SET_REDIRECT_PATH,
  payload: { path },
});

export const setToken = ({ token }) => ({
  type: SET_TOKEN,
  payload: { token },
});

export const setTokenData = ({ tokenData }) => ({
  type: SET_TOKEN_DATA,
  payload: { tokenData },
});

export const setUserData = ({ userData }) => ({
  type: SET_USER_DATA,
  payload: { userData },
});

export const setUserDataLoading = ({ loading }) => ({
  type: SET_USER_DATA_LOADING,
  payload: { loading },
});
