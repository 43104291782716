import { createAction } from '@reduxjs/toolkit';

export const closed = createAction('lobby/websocket/closed');

export const error = createAction('lobby/websocket/error');

export const messageReceived = createAction<{ message: string }>(
  'lobby/websocket/messageReceived'
);

export const opened = createAction('lobby/websocket/opened');
