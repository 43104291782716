import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Col, Row, Spinner } from 'reactstrap';
import { welcomePageLoad } from './store/actions';

export function WelcomePage({ onLoad = () => {} }) {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Container className='d-flex flex-column text-center'>
      <Row>
        <Col>
          <h2>Obrigado por assinar o plano Gold!</h2>
          <p>
            Seu suporte nos ajudará a tornar a plataforma ainda melhor!
            Obrigado!
          </p>
        </Col>
      </Row>
      <Row className='my-5'>
        <Col>
          <p>Confirmando assinatura, você será redirecionado em instantes.</p>
          <Spinner />
        </Col>
      </Row>
      <Row className='my-5'>
        <Col>
          <p>
            Se você já estiver em uma partida, os benefícios Gold serão
            aplicados apenas a partir da próxima.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(welcomePageLoad()),
});
export default connect(null, mapDispatchToProps)(WelcomePage);
