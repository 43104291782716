import React from 'react';
import { Col, ListGroupItem, Row } from 'reactstrap';

import Users, { User } from './users';

export type ItemProps = {
  leftTeam: User[];
  rightTeam: User[];
  map: string;
  lobbyId: string;
};

export default function Item({ leftTeam, rightTeam, map, lobbyId }: ItemProps) {
  return (
    <ListGroupItem tag='a' action href={`/${lobbyId}`}>
      <Row className='justify-content-center'>
        <Col sm='auto'>
          <Users users={leftTeam} />
        </Col>
        <Col sm='auto'>{map}</Col>
        <Col sm='auto'>
          <Users users={rightTeam} />
        </Col>
      </Row>
    </ListGroupItem>
  );
}
