import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

import User from './user';
import { User as UserType } from '../../types';

type UserListProps = {
  className?: string;
  onRemove?: (id: string) => void;
  onSpec?: (id: string) => void;
  onSwap?: (id: string) => void;
  users?: UserType[];
  reverse?: boolean;
};

export default function UserList({
  className = '',
  onRemove = () => {},
  onSwap = () => {},
  onSpec = () => {},
  users = [],
  reverse = false,
}: UserListProps) {
  return (
    <div className={className}>
      <ListGroup flush>
        {users.map(
          ({
            admin,
            avatarUrl,
            badge,
            hasBadge,
            id,
            name,
            removable,
            swappable,
            spectatable,
            tier,
          }) => (
            <ListGroupItem key={id}>
              <User
                admin={admin}
                avatarUrl={avatarUrl}
                badge={badge}
                hasBadge={hasBadge}
                key={id}
                name={name}
                onRemove={() => onRemove(id)}
                onSwap={() => onSwap(id)}
                removable={removable}
                reverse={reverse}
                swappable={swappable}
                tier={tier}
                onSpec={() => onSpec(id)}
                spectatable={spectatable}
              />
            </ListGroupItem>
          )
        )}
      </ListGroup>
    </div>
  );
}
