import React from 'react';
import { Spinner } from 'reactstrap';
import { useFela } from 'react-fela';

export default function Loading() {
  const { css } = useFela();

  return (
    <div className={css(styles.container)}>
      <Spinner />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
