import { get } from 'lodash';

import { LobbyState } from '../slice';
import { getApp } from '../../../store/selectors';

type State = any; // TODO:

export const getLobby = (state: State): LobbyState => getApp(state).lobby;

export const getAborting = (state: State) => getLobby(state).aborting;

export const getChangingMap = (state: State) => getLobby(state).changingMap;

export const getChangingTeam = (state: State) => getLobby(state).changingTeam;

export const getChangingTeamName = (state: State) =>
  getLobby(state).changingTeamName;

export const getCreating = (state: State) => getLobby(state).creating;

export const getData = (state: State) => getLobby(state).data;

export const getFetching = (state: State) => getLobby(state).fetching;

export const getJoinError = (state: State) => getLobby(state).joinError;

export const getJoinErrorCode = (state: State) =>
  getLobby(state).joinError?.error;

export const getJoining = (state: State) => getLobby(state).joining;

export const getLeaving = (state: State) => getLobby(state).leaving;

export const getLoading = (state: State) => getLobby(state).loading;

export const getLobbyShortId = (state: State) =>
  get(getData(state), 'lobbyIdentifier');

export const getLobbyId = (state: State) => get(getData(state), '_id');

export const getStartingMatch = (state: State) => getLobby(state).startingMatch;

export const getSubscribed = (state: State) => getLobby(state).subscribed;

export const hasData = (state: State) => !!getData(state);

export const getChangingPlayerTeamChangesBlocked = (state: State) =>
  getLobby(state).changingPlayerTeamChangesBlocked;
