import React from 'react';
import { useFela } from 'react-fela';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Row,
  UncontrolledCarousel,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSteam, faTwitch } from '@fortawesome/free-brands-svg-icons';
import AnimatedNumber from 'animated-number-react';

import Footer from '../../../components/Footer';
import LandingPlanCard from '../../../app/membership/components/LandingPlanCard';
import useUrlCouponInfo, {
  getCouponCode,
} from '../../../app/membership/hooks/useUrlCouponInfo';
import useStatistics from '../hooks/useStatistics';

import csBackground from '../../../assets/cs-background.jfif';
import demoVideo from '../../../assets/fastdemo-small.mp4';
import bikiniBottom from '../../../assets/bikini-bottom.jpg';
import infernoMirror from '../../../assets/inferno-mirror.jpg';
import infernoOld from '../../../assets/inferno-old.jpg';
import cacheOld from '../../../assets/cache-old.jpg';
import shortDust from '../../../assets/short-dust.png';
import leo from '../../../assets/leo.jpg';
import isa from '../../../assets/isa.png';
import canete from '../../../assets/canete.png';
import logo from '../../../assets/qs24.png';

const STEAM_SIGN_IN_URL = process.env.REACT_APP_STEAM_SIGN_IN_URL;

const carouselItems = [
  {
    src: bikiniBottom,
    altText: '',
    caption: '',
    header: '',
    key: 'bikini',
  },
  {
    src: infernoMirror,
    altText: '',
    caption: '',
    header: '',
    key: 'inferno',
  },
  {
    src: infernoOld,
    altText: '',
    caption: '',
    header: '',
    key: 'inferno-old',
  },
  {
    src: cacheOld,
    altText: '',
    caption: '',
    header: '',
    key: 'cache-old',
  },
  {
    src: shortDust,
    altText: '',
    caption: '',
    header: '',
    key: 'short-dust',
  },
];

const GOLD_PATH = '/gold';

const defaultOnSubscribe = ({ plan }) => {
  const url = (plan !== 'free' && GOLD_PATH) || STEAM_SIGN_IN_URL;
  const coupon = getCouponCode();
  const finalUrl = coupon !== '' ? url + '#coupon' : url;
  window.location.href = finalUrl;
};

const SUBSCRIPTIONS = [
  { type: 'free', price: 0, disabled: false, premium: false },
  { type: 'gold', price: 15.9, disabled: false, premium: true },
];

export default ({
  subscriptionLoading = false,
  subscription = 'free',
  subscriptions = SUBSCRIPTIONS,
  onSubscribe = defaultOnSubscribe,
}) => {
  const { css } = useFela();
  const { t } = useTranslation();
  const statistics = useStatistics();
  const { discount, amount: discountAmount } = useUrlCouponInfo();

  return (
    <div className={css(styles.container)}>
      <Navbar>
        <Container>
          <NavbarBrand tag={Link} to='/'>
            <img
              className={css(styles.navbarLogo)}
              src={logo}
              alt={t('navbar.logo.alt')}
            />
          </NavbarBrand>
          <Button color='primary' outline tag='a' href={STEAM_SIGN_IN_URL} disabled>
            Entrar com a Steam <FontAwesomeIcon icon={faSteam} />
          </Button>
        </Container>
      </Navbar>
      <Container>
        <Row className='my-5'>
          <Row>
            <Col className='d-flex' lg='6'>
              <div className='p-4' style={{ alignSelf: 'center' }}>
                <h2>Partidas de CS:GO na nuvem</h2>
                <p>
                  Servidores poderosos, <strong>gratuitos</strong>, a cliques de
                  distância.
                </p>
                <Button color='primary' tag='a' href={STEAM_SIGN_IN_URL} disabled>
                  Comece agora com a Steam <FontAwesomeIcon icon={faSteam} />
                </Button>
              </div>
            </Col>
            <Col lg='6'>
              <video
                className={`p-4 ${css(styles.video)}`}
                autoPlay
                controls
                controlsList='nodownload nofullscreen'
              >
                <source src={demoVideo} />
              </video>
            </Col>
          </Row>
        </Row>
      </Container>
      <Container>
        <Row className='my-5'>
          <Row>
            <Col className='my-4' md='4'>
              <h3>128 tickrate, 0 var</h3>
              <p>
                Nos nossos servidores{' '}
                <em>otimizados para a mais alta performance</em>, toda bala
                encaixa.
              </p>
            </Col>
            <Col className='my-4' md='4'>
              <h3>A todo instante</h3>
              <p>
                Com a escalabilidade <em>virtualmente infinita</em> da nuvem{' '}
                <em>sempre</em> temos uma sala para você.
              </p>
            </Col>
            <Col className='my-4' md='4'>
              <h3>Em um instante</h3>
              <p>
                Juntar o clã é tão <em>simples</em> quanto compartilhar um link.
                É sério.
              </p>
            </Col>
          </Row>
        </Row>
      </Container>
      <div className='bg-light'>
        <Container>
          <Row className='my-5'>
            <Col>
              <h3 className='text-center'>Uma comunidade em crescimento</h3>
              <Row className='mt-4'>
                <Col md='4'>
                  <div className='p-2 d-flex flex-column align-items-center'>
                    <span className='h3'>
                      <AnimatedNumber
                        value={statistics.userCount}
                        formatValue={(v) => parseInt(v)}
                      />
                    </span>
                    <span>jogadores cadastrados</span>
                  </div>
                </Col>
                <Col md='4'>
                  <div className='p-2 d-flex flex-column align-items-center'>
                    <span className='h3'>
                      <AnimatedNumber
                        value={statistics.minutesOfGameplay}
                        formatValue={(v) => parseInt(v)}
                      />
                    </span>
                    <span>minutos de partida</span>
                  </div>
                </Col>
                <Col md='4'>
                  <div className='p-2 d-flex flex-column align-items-center'>
                    <span className='h3'>
                      <AnimatedNumber
                        value={statistics.matchCount}
                        formatValue={(v) => parseInt(v)}
                      />
                    </span>
                    <span>partidas jogadas</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className='my-5'>
          <Col lg='6'>
            <UncontrolledCarousel className='' items={carouselItems} />
          </Col>
          <Col lg='6' className='p-md-4 my-md-0 my-4 d-flex'>
            <div style={{ alignSelf: 'center' }}>
              <h3>Tem competitivo, tem zueira</h3>
              <p>
                Além dos mapas competitivos que já são de se esperar, temos
                mapas <em>for fun</em>, 3V3 e braço direito. Ainda não está
                convencido de que essa é a melhor plataforma pro seu mix? E se
                eu te contar que temos as versões antigas da Inferno, Nuke,
                Train, Cobble...
              </p>
              <Button color='primary' outline tag='a' href={STEAM_SIGN_IN_URL}>
                Vem testar! <FontAwesomeIcon icon={faSteam} />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <div className='bg-light'>
        <Container>
          <Row className='my-5'>
            <Col>
              <h3 className='text-center'>Com jogadores em primeiro lugar</h3>
              <div className='mt-4 d-flex flex-row justify-content-center'>
                {[
                  {
                    image: isa,
                    linkIcon: faTwitch,
                    linkColor: '#6441a5',
                    linkHref: 'https://twitch.tv/isasudati',
                    displayName: 'isasudati',
                    text:
                      'Estou curtindo muito o quicksv! Fazer mix lá é muito bom mesmo, fora que estamos amando as skins!',
                    shortName: 'Isa',
                  },
                  {
                    image: canete,
                    linkIcon: faTwitch,
                    linkColor: '#6441a5',
                    linkHref: 'https://twitch.tv/canetefps',
                    displayName: 'canetefps',
                    text:
                      'O Quicksv além de ser um server 128, liso, gratuito, nos disponibiliza mapas zueira que fazem a alegria da rapaziada da live, além do suporte incrível quando precisamos.',
                    shortName: 'Canete',
                  },
                ].map(
                  ({
                    displayName,
                    image,
                    linkColor,
                    linkHref,
                    linkIcon,
                    text,
                    shortName,
                  }) => (
                    <Card body style={{ maxWidth: '400px' }} className='m-2'>
                      <div className='d-flex flex-column align-items-center'>
                        <img
                          src={image}
                          alt='ali'
                          style={{
                            maxWidth: '64px',
                            maxHeight: '64px',
                            borderRadius: '100%',
                          }}
                          className='m-2'
                        />
                        <a
                          href={linkHref}
                          style={{ color: linkColor }}
                          className='m-2'
                        >
                          <FontAwesomeIcon icon={linkIcon} /> {displayName}
                        </a>
                        <p className='m-2'>{text}</p>
                        <footer className='blockquote-footer'>
                          {shortName}
                        </footer>
                      </div>
                    </Card>
                  )
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className='my-5'>
          <Col>
            <h3 className='text-center'>
              Benefícios exclusivos para apoiadores
            </h3>
            <Container className='my-4 d-flex flex-column'>
              <Row className='justify-content-center'>
                {subscriptions.map(({ price, type, disabled, premium }) => (
                  <Col key={type} xs='12' md='6' lg='4' className=''>
                    <LandingPlanCard
                      key={type}
                      current={subscription === type}
                      onSubscribe={() => onSubscribe({ plan: type })}
                      price={price}
                      type={type}
                      premium={premium}
                      disabled={true || disabled}
                      discount={discount}
                      discountAmount={discountAmount}
                      loading={subscriptionLoading && !(subscription === type)}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

const styles = {
  video: {
    maxWidth: '100%',
  },
  container: () => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  }),
  homeSection: () => ({
    background: `url(${csBackground})`,
    backgroundSize: 'cover',
  }),
  footerSection: () => ({
    backgroundColor: '#0b0c10',
    height: 'auto',
    flexGrow: 1,
    color: '#444',
  }),
  navbarLogo: () => ({
    height: '40px',
  }),
};
