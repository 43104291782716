import { put, takeEvery } from 'redux-saga/effects';

import { unsubscribe, pageUnloaded as pageUnloadedAction } from '../slice';

export default function* pageUnloaded() {
  yield put(unsubscribe());
}

export function* watchPageUnloaded() {
  yield takeEvery(pageUnloadedAction.toString(), pageUnloaded);
}
