import React from 'react';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import DiscordContactInfo from './DiscordContactLink';
import InstagramInfo from './InstagramInfo';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Container className='py-4'>
      <div>
        <p>{t('footer.copyright')}</p>
        <div className='my-1 d-flex flex-row'>
          <DiscordContactInfo className='m-1' />
          <InstagramInfo className='m-1' />
        </div>
        <p>
          <small>{t('footer.valveAffiliation')}</small>
        </p>
      </div>
    </Container>
  );
}
