import React, { useState } from 'react';
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export type JoinLobbyProps = {
  className?: string;
  onJoin?: (id: string) => void;
};

export default function JoinLobby({
  className = '',
  onJoin = () => {},
}: JoinLobbyProps) {
  const { t } = useTranslation();

  const [code, setCode] = useState('');

  return (
    <div className={className}>
      <h2>{t('platform.home.actions.joinLobby.joinLobby')}</h2>
      <div>
        <InputGroup>
          <Input
            placeholder={t('platform.home.actions.joinLobby.lobbyCode')}
            value={code}
            onChange={({ target: { value } }) => setCode(value)}
          />
          <InputGroupAddon addonType='append'>
            <Button onClick={() => onJoin(code)}>
              {t('platform.home.actions.joinLobby.join')}
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    </div>
  );
}
