import React, { useState, useEffect } from 'react';

import { StatField, UserStats } from '../../../apis/leaderboard';
import {
  getLeaderboardTop,
  TopLeaderboardResponse,
} from '../../../apis/leaderboard/api';
import { getUserBadgeInfo } from '../../../utils/badge';
import LeaderboardComponent from '../components/leaderboard';

const DEFAULT_SORT_KEY = 'score';
const SORT_KEYS: StatField[] = [
  'score',
  'kills',
  'assists',
  'mvp',
  'kdr',
  'accuracy',
  'headshots',
  'ak47',
  'awp',
  'usp_silencer',
  'glock',
  'deagle',
  'revolver',
  'knife',
  'taser',
];

export default function Leaderboard() {
  const [sortKey, setSortKey] = useState<StatField>(DEFAULT_SORT_KEY);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<TopLeaderboardResponse>();

  useEffect(() => {
    getLeaderboardTop({ field: sortKey }).then(({ data }) => {
      setResponse(data);
      setLoading(false);
    });
    setLoading(true);
  }, [sortKey]);

  const onChangeSort = (key: StatField) => setSortKey(key);

  const currentUserStats = response?.userStats;
  const currentUserSteamId64 = currentUserStats?.steamId64;

  const mapUserStats = (
    { steamId64, user, ...stats }: UserStats,
    index: number
  ) => ({
    id: steamId64,
    ...getUserBadgeInfo(user),
    isUser: steamId64 === currentUserSteamId64,
    avatarUrl: user.avatar.small,
    name: user.nickname,
    stat: stats[sortKey],
    position: index + 1,
  });

  let stats = response?.leaderboard.map(mapUserStats);

  const userPosition = response?.leaderboardUserPosition;
  const leaderboardLength = response?.leaderboard.length || 0;
  const userStats = response?.userStats;
  if (userPosition && userStats && userPosition > leaderboardLength)
    stats = stats?.concat(mapUserStats(userStats, userPosition - 1)); // XXX: deep gambiarra territory
  const playerCount = response?.total;

  return (
    <LeaderboardComponent
      loading={loading}
      onChangeSortKey={onChangeSort}
      sortKeys={SORT_KEYS}
      sortKey={sortKey}
      userStats={stats}
      playerCount={playerCount}
    />
  );
}
