import { call, put, select, takeEvery } from 'redux-saga/effects';

import { PERSIST_STATE } from '../actionTypes';
import { statePersisted } from '../actions';
import { getPersistor } from '../selectors';

export default function* persistState() {
  const persistor = yield select(getPersistor);

  if (!persistor) return;

  yield call([persistor, 'flush']);
  yield put(statePersisted());
}

export function* watchPersistState() {
  yield takeEvery(PERSIST_STATE, persistState);
}
