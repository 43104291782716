import React from 'react';
import { useFela } from 'react-fela';

import { UserBadge } from '../../../apis/server';
import Avatar from '../../components/Avatar';
import BadgedAvatar from '../../components/BadgedAvatar';

export type UserDisplayProps = {
  hasBadge?: boolean;
  badge?: UserBadge;
  name?: string;
  avatarUrl?: string;
};

export default function UserDisplay({
  name = '',
  hasBadge = false,
  badge,
  avatarUrl = '',
}: UserDisplayProps) {
  const { css } = useFela();

  const styles = {
    avatar: {
      margin: '0 .4em',
    },
    container: {
      display: 'inline-flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    item: {
      margin: '0 .2em',
    },
    username: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  } as const;

  return (
    <div className={css(styles.container)}>
      {hasBadge ? (
        <BadgedAvatar
          alt={name}
          badge={badge}
          className={css(styles.avatar)}
          url={avatarUrl}
        />
      ) : (
        <Avatar alt={name} className={css(styles.avatar)} url={avatarUrl} />
      )}
      <span className={css(styles.item, styles.username)}>{name}</span>
    </div>
  );
}
