import {
  SET_FETCHING_ONGOING_LOBBIES,
  SET_ONGOING_LOBBIES,
} from './actionTypes';

const initialState = {
  fetchingOngoingLobbies: false,
  ongoingLobbies: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FETCHING_ONGOING_LOBBIES:
      return setFetchingOngoingLobbies(state, action);
    case SET_ONGOING_LOBBIES:
      return setOngoingLobbies(state, action);
    default:
      return state;
  }
};

const setFetchingOngoingLobbies = (state, action) => {
  const {
    payload: { fetching },
  } = action;

  return {
    ...state,
    fetchingOngoingLobbies: fetching,
  };
};

const setOngoingLobbies = (state, action) => {
  const {
    payload: { lobbies },
  } = action;

  return {
    ...state,
    ongoingLobbies: lobbies,
  };
};
