import React from 'react';
import { ListGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Item, { ItemProps } from './item';

export type LobbyListProps = {
  className?: string;
  lobbies: ItemProps[];
};

export default function LobbyList({ className = '', lobbies }: LobbyListProps) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <h2 className='text-center'>
        {t('platform.home.actions.previousLobbies')}
      </h2>
      <div>
        <ListGroup>
          {lobbies.map(({ leftTeam, lobbyId, map, rightTeam }) => (
            <Item
              key={lobbyId}
              leftTeam={leftTeam}
              lobbyId={lobbyId}
              map={map}
              rightTeam={rightTeam}
            />
          ))}
        </ListGroup>
      </div>
    </div>
  );
}
