import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ptBR } from './translations';

i18n.use(initReactI18next).init({
  resources: {
    ptBR: {
      translation: ptBR,
    },
  },
  lng: 'ptBR',
  fallbackLng: 'ptBR',
});

export default i18n;
