import { useEffect, useState } from 'react';

const STARTING_STATISTICS = {
  userCount: 1700,
  minutesOfGameplay: 40000,
  matchCount: 1200,
};

const STATISTICS_URL = process.env.REACT_APP_STATISTICS_URL;

export default (initial = STARTING_STATISTICS) => {
  const [statistics, setStatistics] = useState(initial);

  useEffect(() => {
    fetch(STATISTICS_URL)
      .then((response) => response.json())
      .then(({ nUsers, totalMatchTimeInMinutes, nMatches }) => {
        setStatistics({
          userCount: nUsers,
          minutesOfGameplay: totalMatchTimeInMinutes,
          matchCount: nMatches,
        });
      });
  }, [setStatistics]);

  return statistics;
};
