import { call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { setAborting, setData, abort as abortAction } from '../slice';
import { getLobbyId } from '../selectors';
import { updateMatchAbort } from '../../../../apis/server';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import i18n from '../../../../i18n';

export default function* abort() {
  const matchId: string | undefined = yield select(getLobbyId);

  if (!matchId) return;

  yield put(setAborting(true));

  try {
    const { data } = yield call(consistentlyRequest, updateMatchAbort, {
      matchId,
    });

    yield put(setData(data));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.abort'));
  } finally {
    yield put(setAborting(false));
  }
}

export function* watchAbort() {
  yield takeEvery(abortAction.toString(), abort);
}
