import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useFela } from 'react-fela';
import { Alert, Container } from 'reactstrap';

import Navbar from './containers/Navbar';
import Footer from '../components/Footer';

import Home from './home/page';
import Lobby from './lobby/Page';
import Membership from './membership/Page';
import WelcomeToGold from './membership/WelcomePage';
import { connect } from 'react-redux';
import { load } from './meta/store/actions';

export function App({ onLoad = () => {} }) {
  const { css } = useFela();

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const styles = {
    container: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    footerContainer: {},
  };

  return (
    <div className={css(styles.container)}>
      <Navbar />
      <Container className='my-3'>
        <Alert>
          Nossos servidores ainda não foram atualizados para funcionar com o CS2. Pedimos desculpas pelo transtorno.
        </Alert>
      </Container>
      <Switch>
        <Route path='/home'>
          <Home />
        </Route>
        <Route path={['/membership', '/gold']}>
          <Membership />
        </Route>
        <Route path='/welcome_to_gold'>
          <WelcomeToGold />
        </Route>
        <Route path='/:id'>
          <Lobby />
        </Route>
      </Switch>
      <div className='bg-light'>
        <Footer />
      </div>
    </div>
  );
}

export default connect(null, (dispatch) => ({
  onLoad: () => dispatch(load()),
}))(App);
