import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function InfoSection() {
  return (
    <div>
      <div>
        <h5>Conexão Segura</h5>
        <p>
          Seu pagamento será processado através da{' '}
          <a
            href='https://stripe.com/br'
            target='_blank'
            rel='noopener noreferrer'
          >
            plataforma Stripe
          </a>
          , que nos repassa <em>apenas</em> o seu e-mail.
        </p>
      </div>
      <div>
        <h5>Por que assinar?</h5>
        <p>
          No momento, nós, os desenvolvedores, bancamos todos os custos da
          plataforma do nosso bolso. Além dos diversos benefícios, assinar nos
          ajudará a manter as luzes acesas e, um dia, a crescer a equipe e a
          comunidade de uma forma saudável.
        </p>
      </div>
      <div>
        <h5>Sobre os benefícios</h5>
        <p>
          Benefícios marcados com <FontAwesomeIcon icon={faUsers} />, como o
          rollback de rounds, ficam disponíveis para <b>todos</b> os membros da
          sala, inclusive os <em>free</em>.
        </p>
        <p>
          Benefícios marcados com <FontAwesomeIcon icon={faUser} />, como as
          skins, ficam disponíveis <b>apenas</b> para os membros <em>gold</em>.
          Isto é, só quem assinar <em>gold</em> poderá usar skins.
        </p>
      </div>
    </div>
  );
}
