import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { setData, setFetching, fetchData as fetchDataAction } from '../slice';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import { getMatchById } from '../../../../apis/server';
import i18n from '../../../../i18n';

export default function* fetchData(id: string) {
  yield put(setFetching(true));

  try {
    const { data } = yield call(consistentlyRequest, getMatchById, {
      matchId: id,
    });

    yield put(setData(data));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.fetchData'));
  } finally {
    yield put(setFetching(false));
  }
}

export function* watchFetchData() {
  yield takeEvery(fetchDataAction.toString(), function* ({
    payload: { id },
  }: ReturnType<typeof fetchDataAction>) {
    yield call(fetchData, id);
  });
}
