import { call, takeEvery } from 'redux-saga/effects';

import { TOKEN_RECEIVED } from '../actionTypes';
import redirectAfterAuth from './redirectAfterAuth';
import setToken from './setToken';
import fetchUserData from './fetchUserData';
import persistState from '../../../store/meta/sagas/persistState';

export function* watchTokenReceived() {
  yield takeEvery(TOKEN_RECEIVED, tokenReceived);
}

export default function* tokenReceived({ payload: { token } }) {
  yield call(setToken, { token });
  yield call(fetchUserData);
  yield call(persistState);
  yield call(redirectAfterAuth);
}
