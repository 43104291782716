import React, { useState } from 'react';
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

const TOOLTIP_MESSAGE_TIMEOUT = 3500;
const COPY_TOOLTIP_TEXT = 'platform.components.copyInput.copyTooltip';
const COPIED_TOOLTIP_TEXT = 'platform.components.copyInput.copiedTooltip';
const ERROR_TOOLTIP_TEXT = 'platform.components.copyInput.copyErrorTooltip';

type UrlInputProps = {
  processValue?: (value: string) => string;
  value?: string;
};

export default function UrlInput({
  processValue = (value) => value,
  value = '',
}: UrlInputProps) {
  const { t } = useTranslation();
  const [uniqueId] = useState(() => uuid());
  const [tooltipText, setTooltipText] = useState(COPY_TOOLTIP_TEXT);

  const displayTooltipMessage = (text: string) => {
    setTooltipText(text);
    setTimeout(
      () => setTooltipText(COPY_TOOLTIP_TEXT),
      TOOLTIP_MESSAGE_TIMEOUT
    );
  };

  const onCopy = () => {
    const processedValue = !!processValue ? processValue(value) : value;

    copyToClipboard(processedValue)
      .then(() => displayTooltipMessage(COPIED_TOOLTIP_TEXT))
      .catch(() => displayTooltipMessage(ERROR_TOOLTIP_TEXT));
  };

  return (
    <>
      <InputGroup>
        <Input id={`input-${uniqueId}`} disabled value={value} />
        <InputGroupAddon addonType='append'>
          <Button id={`input-${uniqueId}-copy-button`} outline onClick={onCopy}>
            <FontAwesomeIcon icon={faClone} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
      <UncontrolledTooltip target={`input-${uniqueId}-copy-button`}>
        {t(tooltipText)}
      </UncontrolledTooltip>
    </>
  );
}

const copyToClipboard = async (text: string) => {
  const { state } = await navigator.permissions.query({
    name: 'clipboard-write',
  });

  if (state === 'granted' || state === 'prompt') {
    return navigator.clipboard.writeText(text);
  }

  throw new Error('no clipboard access!');
};
