import { call, put, takeEvery } from 'redux-saga/effects';

import { join, pageLoaded as pageLoadedAction } from '../slice';

export default function* pageLoaded({ lobbyId }: { lobbyId: string }) {
  yield put(join({ id: lobbyId }));
}

export function* watchPageLoaded() {
  yield takeEvery(pageLoadedAction.toString(), function* ({
    payload: { lobbyId },
  }: ReturnType<typeof pageLoadedAction>) {
    yield call(pageLoaded, { lobbyId });
  });
}
