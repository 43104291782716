import { call, put, select, takeEvery } from 'redux-saga/effects';

import { subscribe, setData, joined as joinedAction } from '../slice';
import { getLobbyId } from '../selectors';
import { Lobby } from '../../../../apis/server';

export default function* joined(data: Lobby | undefined) {
  if (!data) return;

  yield put(setData(data));
  const lobbyId: string | undefined = yield select(getLobbyId);
  yield put(subscribe({ matchId: lobbyId! })); // if data != undefined, then lobbyId should also != undefined
}

export function* watchJoined() {
  yield takeEvery(joinedAction.toString(), function* ({
    payload: { data },
  }: ReturnType<typeof joinedAction>) {
    yield call(joined, data);
  });
}
