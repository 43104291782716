import React from 'react';
import Player from 'react-player';

const PARENT = ['quicksv.com', 'dev.quicksv.com'];
const LAYOUT = 'video-with-chat';

export default function PartnerEmbed({ url }: { url: string }) {
  return (
    <Player
      url={url}
      width='100%'
      controls
      muted
      config={{
        twitch: {
          options: {
            parent: PARENT,
            layout: LAYOUT,
          },
        },
      }}
    />
  );
}
