import { call, put, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';

import { joinLobby } from '../../../../apis/server';
import { UNKNOWN } from '../../../../apis/server/error-codes';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import { joined, setJoining, setJoinError, join as joinAction } from '../slice';

export default function* join(id: string) {
  yield put(setJoining(true));

  try {
    const { data } = yield call(consistentlyRequest, joinLobby, { id });

    yield put(joined({ data }));
  } catch (error) {
    const code = get(error, 'response.data.code', UNKNOWN);

    yield put(setJoinError({ error: code }));
  } finally {
    yield put(setJoining(false));
  }
}

export function* watchJoin() {
  yield takeEvery(joinAction.toString(), function* ({
    payload: { id },
  }: ReturnType<typeof joinAction>) {
    yield call(join, id);
  });
}
