import { call, put, select, takeEvery } from 'redux-saga/effects';

import { CHECK } from '../actionTypes';
import { setRedirectPath } from '../actions';
import { getToken } from '../selectors';
import { hasExpired } from '../../utils/token';
import persistState from '../../../store/meta/sagas/persistState';
import { logRocketIdentify } from './utils';

const LOGIN_URL = process.env.REACT_APP_STEAM_SIGN_IN_URL;

export function* watchCheck() {
  yield takeEvery(CHECK, check);
}

export default function* check({ payload: { location } }) {
  const token = yield select(getToken);

  if (token) logRocketIdentify({ token });

  if (isTokenValid(token)) return;

  yield put(setRedirectPath({ path: location }));
  yield call(persistState);
  yield call(redirectToLogin);
}

const isTokenValid = (token) => !!token && !hasExpired(token);

const redirectToLogin = () => {
  window.location.href = LOGIN_URL;
};
