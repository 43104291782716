import React from 'react';
import { bool } from 'prop-types';
import { useFela } from 'react-fela';
import { Spinner } from 'reactstrap';

export default function FullPageLoading({ dark }) {
  const { css } = useFela();

  const bgClass = dark ? 'bg-dark' : '';
  const containerClasses = `${css(styles.container)} ${bgClass}`;

  const spinnerColor = dark ? 'light' : 'dark';

  return (
    <div className={containerClasses}>
      <Spinner color={spinnerColor} />
    </div>
  );
}
FullPageLoading.propTypes = {
  dark: bool,
};

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
