import { useState, useEffect } from 'react';

import { getPromotionByDiscountCode } from '../../../apis/membership';

const DEFAULT_COUPON_CODE = process.env.REACT_APP_DEFAULT_COUPON_CODE;

export const getCouponCode = () => getUrlCoupon() || DEFAULT_COUPON_CODE;

export const getUrlCoupon = () => {
  const url = new URL(window.location.href);
  const coupon = url.hash.substring(1);
  return coupon;
};

const NO_DISCOUNT = { discount: false, amount: 0 };

export const getDiscountInfo = async (discountCode) => {
  if (discountCode === '') return NO_DISCOUNT;

  const { status, data } = await getPromotionByDiscountCode({ discountCode });

  if (status !== 200) return NO_DISCOUNT;
  if (!data.active) return NO_DISCOUNT;
  if (!data.coupon.valid) return NO_DISCOUNT;

  const {
    coupon: { percent_off: percentOff, amount_off: amountOff },
  } = data;

  const discount = (percentOff && 'percent') || (amountOff && 'flat') || false;
  const amount = discount === 'percent' ? percentOff / 100 : amountOff;

  return {
    discount,
    amount,
  };
};

export default () => {
  const [couponInfo, setCouponInfo] = useState(NO_DISCOUNT);

  useEffect(() => {
    const coupon = getCouponCode();
    getDiscountInfo(coupon).then(setCouponInfo);
  }, [setCouponInfo]);

  return couponInfo;
};
