import { toast } from 'react-toastify';
import {
  takeEvery,
  call,
  all,
  put,
  select,
  debounce,
} from 'redux-saga/effects';
import { addRating, MatchRating } from '../../../../apis/server';
import i18n from '../../../../i18n';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import {
  setData,
  setLocalRating,
  setUpdatingRating,
  updateRating as updateRatingAction,
} from '../slice';
import { getLobbyId } from '../selectors';
import { getRating, getLocalRating } from '../selectors/page';
import { createAction, PayloadAction } from '@reduxjs/toolkit';

export const DEBOUNCE_TIME = 800;

const requestRatingUpdateAction = createAction<MatchRating>(
  'lobby/sagas/requestRatingUpdate'
);

export default function* updateRating(rating?: MatchRating) {
  const previousRating: MatchRating = yield select(getRating);
  yield put(setLocalRating(rating));

  if (!rating) return;

  const scoreChanged = previousRating.score !== rating?.score;

  if (scoreChanged) {
    yield call(requestRatingUpdate, rating);
    return;
  }

  yield put(requestRatingUpdateAction(rating));
}

function* requestRatingUpdate(rating: MatchRating) {
  const matchId: string | undefined = yield select(getLobbyId);

  if (!matchId) return;

  yield put(setUpdatingRating(true));

  try {
    const { score, message } = rating;
    const { data } = yield call(consistentlyRequest, addRating, {
      matchId,
      score,
      message,
    });

    yield put(setData(data));

    const localRating: MatchRating = yield select(getLocalRating);
    if (ratingsEqual(localRating, rating)) {
      yield put(setLocalRating(undefined));
    }
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.updateRating'));
  } finally {
    yield put(setUpdatingRating(false));
  }
}

const ratingsEqual = (a: MatchRating, b: MatchRating) =>
  a.score === b.score && a.message === b.message;

export function* watchUpdateRating() {
  yield all([
    debounce(DEBOUNCE_TIME, requestRatingUpdateAction.toString(), function* ({
      payload: rating,
    }: ReturnType<typeof requestRatingUpdateAction>) {
      yield call(requestRatingUpdate, rating);
    }),
    takeEvery(updateRatingAction.toString(), function* ({
      payload: { rating },
    }: PayloadAction<{ rating?: MatchRating }>) {
      yield call(updateRating, rating);
    }),
  ]);
}
