import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as app } from '../app/store';
import { reducer as authentication } from '../authentication/store';
import history from '../history';
import { reducer as meta } from './meta';
import { reducer as settings } from '../settings/store';

export default combineReducers({
  app,
  authentication,
  meta,
  router: connectRouter(history),
  settings,
});
