import React from 'react';
import { useFela } from 'react-fela';

export type AvatarProps = {
  image: string;
  name?: string;
};

export default function Avatar({ image, name = '' }: AvatarProps) {
  const { css } = useFela();

  const style = {
    borderRadius: '.4em',
    maxHeight: '1.5em',
    margin: '0 .15em',
  };

  return <img className={css(style)} alt={name} src={image} />;
}
