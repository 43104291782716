import React, { useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getAborting,
  getJoining,
  getCreating,
  getJoinErrorCode,
} from './store/selectors';
import {
  getServerInfo,
  isFinished,
  isReady,
  isServerLoading,
  isServerRunning,
  isAbortable,
  shouldAskForRating,
  getRating,
  getUpdatingRating,
  getNotifyOnReady,
  isFinishing,
  getDemoUrl,
  getDemoState,
} from './store/selectors/page';
import {
  abort,
  create,
  leave,
  pageLoaded,
  pageUnloaded,
  updateRating,
} from './store/slice';

import ErrorModal from './components/error-modal';
import FinishedModal from './components/finished-modal';
import Room from './containers/room';
import ServerModal from './components/server-modal';
import { changeNotifyOnLobbyReady } from '../../settings/store/actions';
import {
  getUserPlayer,
  getLobbyTier,
  getGoldUserNames,
} from './store/selectors/lobby';

export function Page({
  creatingNewLobby = false,
  demoState = 'unavailable',
  demoUrl = undefined,
  finished = false,
  finishing = false,
  joinError = undefined,
  joining = false,
  matchAbortable = false,
  matchAborting = false,
  onAbortMatch = () => {},
  onLoad = (_id) => {},
  onUnmount,
  ready = true,
  serverInfo,
  serverLoading,
  serverModalOpen,
  askForRating = false,
  rating = undefined,
  updatingRating = false,
  onRatingChange = (_rating) => {},
  onNewLobby = () => {},
  onNotifyOnReadyChange = () => {},
  notifyOnReady = false,
  onLeave = () => {},
  autoShowGotvConnect = false,
  showGameConnect = true,
  showGotvConnect = true,
  showStreamerPartner = false,
  lobbyTier = 'free',
  goldUserNames = [],
}) {
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;

    onLoad(id);

    return () => onUnmount();
  }, [id, onLoad, onUnmount]);

  if (joinError) {
    return (
      <div>
        <ErrorModal
          className='modal-dialog-centered'
          error={joinError}
          open={!!joinError}
        />
      </div>
    );
  }

  if (joining || !ready) {
    return (
      <div className='d-flex justify-content-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Room />
      <FinishedModal
        backdrop='static'
        className='modal-dialog-centered'
        creatingNewLobby={creatingNewLobby}
        keyboard={false}
        open={finished || finishing}
        demoState={demoState}
        demoUrl={demoUrl}
        askForRating={askForRating}
        rating={rating}
        onRatingChange={onRatingChange}
        ratingLoading={updatingRating}
        onNewLobby={onNewLobby}
        onLeave={onLeave}
      />
      <ServerModal
        abortable={matchAbortable}
        aborting={matchAborting}
        backdrop='static'
        className='modal-dialog-centered'
        keyboard={false}
        onAbort={onAbortMatch}
        onNotifyOnReadyChange={onNotifyOnReadyChange}
        notifyOnReady={notifyOnReady}
        open={serverModalOpen}
        loading={serverLoading}
        serverInfo={serverInfo}
        autoShowGotvConnect={autoShowGotvConnect}
        showGameConnect={showGameConnect}
        showGotvConnect={showGotvConnect}
        showStreamerPartner={showStreamerPartner}
        lobbyTier={lobbyTier}
        goldUserNames={goldUserNames}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  creatingNewLobby: getCreating(state),
  demoState: getDemoState(state),
  demoUrl: getDemoUrl(state),
  finished: isFinished(state),
  finishing: isFinishing(state),
  joinError: getJoinErrorCode(state),
  joining: getJoining(state),
  matchAbortable: isAbortable(state),
  matchAborting: getAborting(state),
  ready: isReady(state),
  serverModalOpen: isServerLoading(state) || isServerRunning(state),
  serverInfo: getServerInfo(state),
  serverLoading: isServerLoading(state),
  askForRating: shouldAskForRating(state),
  rating: getRating(state),
  updatingRating: getUpdatingRating(state),
  notifyOnReady: getNotifyOnReady(state),
  showGameConnect: getUserPlayer(state)?.team !== 'SPEC',
  showGotvConnect: true,
  autoShowGotvConnect: getUserPlayer(state)?.team === 'SPEC',
  showStreamerPartner: true,
  lobbyTier: getLobbyTier(state),
  goldUserNames: getGoldUserNames(state),
});
const mapDispatchToProps = (dispatch) => ({
  onAbortMatch: () => dispatch(abort()),
  onLoad: (lobbyId) => dispatch(pageLoaded({ lobbyId })),
  onUnmount: () => dispatch(pageUnloaded()),
  onRatingChange: (rating) => dispatch(updateRating({ rating })),
  onNewLobby: () => dispatch(create()),
  onNotifyOnReadyChange: (notifyOnLobbyReady) =>
    dispatch(changeNotifyOnLobbyReady({ notifyOnLobbyReady })),
  onLeave: () => dispatch(leave()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);
