import { useState, useMemo } from 'react';
import { groupBy, map, orderBy } from 'lodash';

import { getHistory, Lobby } from '../../../apis/server';

const structureHistory = (lobbies: Lobby[]) =>
  orderBy(
    map(
      groupBy(lobbies, ({ creationDate }) => creationDate.substr(0, 10)),
      (lobbies, creationDay) => ({
        creationDay: new Date(creationDay),
        lobbies: orderBy(lobbies, ['creationDate'], ['desc']),
      })
    ),
    ['creationDay'],
    ['desc']
  );

export default ({ pageSize }: { pageSize?: number } = {}) => {
  const [nextPage, setNextPage] = useState(0);
  const [matches, setMatches] = useState<Lobby[]>([]);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(false);

  const fetchNextPage = useMemo(
    () => async () => {
      if (!hasNext) return;

      setLoading(true);
      try {
        const { data } = await getHistory({ page: nextPage, pageSize });
        setNextPage(nextPage + 1);
        setMatches(matches.concat(data));
        if (data.length === 0) setHasNext(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [nextPage, setNextPage, pageSize, matches, setMatches, hasNext, setHasNext]
  );

  const history = structureHistory(matches);

  return {
    hasNext,
    loading,
    history,
    matches,
    nextPage,
    fetchNextPage,
  };
};
