import { put, select, takeEvery } from 'redux-saga/effects';

import { LOAD } from '../../../../meta/store/actionTypes';
import { fetchUserData } from '../../../../../authentication/store/actions';
import { userHasMembership } from '../../selectors';

export default function* appLoad() {
  // refetch user data if there is no membership data in local state
  const membershipDataAvailable = yield select(userHasMembership);
  if (!membershipDataAvailable) {
    yield put(fetchUserData());
  }
}

export function* watch() {
  yield takeEvery(LOAD, appLoad);
}
