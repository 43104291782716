import { call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { find } from 'lodash';

import {
  setChangingMap,
  setData,
  changeMap as changeMapAction,
} from '../slice';
import { getLobbyId } from '../selectors';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import { updateLobbyChangeMap } from '../../../../apis/server';
import i18n from '../../../../i18n';
import availableMaps from '../../helpers/available-maps';
import { getCurrent } from '../../presenters/map';
import { getMap, getMapType } from '../selectors/lobby';

export default function* changeMap(id: string) {
  const currentMapId: string | undefined = yield select(getCurrentMapId);

  if (id === currentMapId) return;

  const matchId: string | undefined = yield select(getLobbyId);

  const currentMap = find(availableMaps, { id });

  if (!currentMap) return;

  const {
    map,
    extra: { mapType },
  } = currentMap;

  yield put(setChangingMap(true));

  try {
    const { data } = yield call(consistentlyRequest, updateLobbyChangeMap, {
      map,
      matchId,
      mapType,
    });

    yield put(setData(data));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.changeMap'));
  } finally {
    yield put(setChangingMap(false));
  }
}

const getCurrentMapId = (state: any): string | undefined => {
  const map = getMap(state);
  const mapType = getMapType(state);

  if (!map || !mapType) return undefined;

  const { id } = getCurrent(map, mapType);
  return id;
};

export function* watchChangeMap() {
  yield takeEvery(changeMapAction.toString(), function* ({
    payload: { id },
  }: ReturnType<typeof changeMapAction>) {
    yield call(changeMap, id);
  });
}
