import {
  LOAD,
  SET_FETCHING_ONGOING_LOBBIES,
  SET_ONGOING_LOBBIES,
} from './actionTypes';

export const load = () => ({
  type: LOAD,
});

export const setFetchingOngoingLobbies = ({ fetching }) => ({
  type: SET_FETCHING_ONGOING_LOBBIES,
  payload: { fetching },
});

export const setOngoingLobbies = ({ lobbies }) => ({
  type: SET_ONGOING_LOBBIES,
  payload: { lobbies },
});
