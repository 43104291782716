import React from 'react';
import { Container, Modal, ModalBody } from 'reactstrap';

import Loading from './loading';
import ConnectionInfo from './connection-info';

type ServerModalProps = {
  abortable?: boolean;
  aborting?: boolean;
  backdrop?: boolean | 'static';
  className?: string;
  keyboard?: boolean;
  loading?: boolean;
  onAbort?: () => void;
  onNotifyOnReadyChange: () => void;
  notifyOnReady: boolean;
  open: boolean;
  serverInfo: {
    ipAddress: string;
    port: number;
    password: string;
  };
  showGameConnect?: boolean;
  showGotvConnect?: boolean;
  autoShowGotvConnect?: boolean;
  showStreamerPartner?: boolean;
  lobbyTier?: 'free' | 'gold';
  goldUserNames?: string[];
};

export default function ServerModal({
  abortable = false,
  aborting = false,
  backdrop = 'static',
  className = '',
  keyboard = false,
  loading = false,
  notifyOnReady = false,
  onAbort = () => {},
  onNotifyOnReadyChange = () => {},
  open = false,
  serverInfo,
  showGameConnect = true,
  showGotvConnect = true,
  showStreamerPartner = false,
  autoShowGotvConnect = false,
  lobbyTier = 'free',
  goldUserNames = [],
}: ServerModalProps) {
  const { ipAddress, port, password } = serverInfo;

  return (
    <Modal
      className={className}
      isOpen={open}
      backdrop={backdrop}
      keyboard={keyboard}
      size={loading && showStreamerPartner ? 'lg' : undefined}
    >
      <ModalBody>
        <Container>
          {loading ? (
            <Loading
              abortable={abortable}
              aborting={aborting}
              onAbort={onAbort}
              onNotifyOnReadyChange={onNotifyOnReadyChange}
              notifyOnReady={notifyOnReady}
              showStreamerPartner={showStreamerPartner}
              lobbyTier={lobbyTier}
              goldUserNames={goldUserNames}
            />
          ) : (
            <ConnectionInfo
              abortable={abortable}
              aborting={aborting}
              ipAddress={ipAddress}
              password={password}
              onAbort={onAbort}
              port={port}
              showGameConnect={showGameConnect}
              showGotvConnect={showGotvConnect}
              autoShowGotvConnect={autoShowGotvConnect}
            />
          )}
        </Container>
      </ModalBody>
    </Modal>
  );
}
