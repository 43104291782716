import { filter, map } from 'lodash';

import * as api from '../../../apis/server/types';
import { Team, User } from '../types';
import { getSmallAvatarUrl, isAdmin, getTier, getBadgeInfo } from './player';

const API_TEAMS = {
  left: 'TEAM_1' as const,
  right: 'TEAM_2' as const,
  spec: 'SPEC' as const,
};

const TEAMS = {
  TEAM_1: 'left' as const,
  TEAM_2: 'right' as const,
  SPEC: 'spec' as const,
};

export const getTeams = (
  { players }: api.Lobby,
  userPlayer: any,
  teamNames: Record<api.PlayerTeam, string>,
  changingTeamName: Record<Team['id'], boolean>
) => ({
  left: mapTeam(
    players,
    API_TEAMS.left,
    userPlayer,
    teamNames,
    changingTeamName
  ),
  right: mapTeam(
    players,
    API_TEAMS.right,
    userPlayer,
    teamNames,
    changingTeamName
  ),
  spec: mapTeam(
    players,
    API_TEAMS.spec,
    userPlayer,
    teamNames,
    changingTeamName
  ),
});

const mapTeam = (
  players: api.Player[],
  teamId: api.PlayerTeam,
  userPlayer: api.Player,
  teamNames: Record<api.PlayerTeam, string>,
  changingTeamName: Record<Team['id'], boolean>
): Team => {
  const userId = userPlayer.user._id;
  const isUserAdmin = isAdmin(userPlayer);

  const teamPlayers = filter(players, { team: teamId });
  const playerIsUser = (player: api.Player) => player.user._id === userId;
  const isPlayerControllable = (player: api.Player) =>
    isUserAdmin && !playerIsUser(player);
  const joinable = teamId !== userPlayer.team;

  const mapPlayer = (player: api.Player): User => {
    const controllable = isPlayerControllable(player);

    return {
      ...getBadgeInfo(player),
      admin: isAdmin(player),
      avatarUrl: getSmallAvatarUrl(player),
      id: player.user._id,
      name: player.user.nickname,
      tier: getTier(player),
      removable: controllable,
      swappable: controllable,
      spectatable: controllable,
    };
  };

  return {
    id: TEAMS[teamId],
    name: teamNames[teamId],
    users: map(teamPlayers, mapPlayer),
    nameLoading: changingTeamName[TEAMS[teamId]],
    nameEditable: isUserAdmin,
    joinable,
  };
};

export const mapPlayerTeam = (player: api.Player) => TEAMS[player.team];

export const mapToApiTeam = (team: Team['id']): api.PlayerTeam =>
  API_TEAMS[team];

export const getOpposingTeam = (team: 'left' | 'right') =>
  team === 'left' ? 'right' : 'left';
