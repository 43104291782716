import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducer from './reducer';

const persistConfig = {
  key: 'authentication',
  storage,
  whitelist: ['redirectPath', 'token', 'tokenData', 'userData'],
};

export default persistReducer(persistConfig, reducer);
