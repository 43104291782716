import { call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  changeUserTeam as changeUserTeamAction,
  setChangingUserTeam,
  setData,
} from '../slice';
import { getLobbyId } from '../selectors';
import { getPlayerByUserId } from '../selectors/lobby';
import { Player, updateLobbyChangeTeam } from '../../../../apis/server';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import i18n from '../../../../i18n';
import { mapToApiTeam } from '../../presenters/team';

export default function* changeUserTeam(
  userId: string,
  teamId: 'left' | 'right' | 'spec'
) {
  const matchId: string | undefined = yield select(getLobbyId);
  const player: Player | undefined = yield select(getPlayerByUserId, userId);

  if (!player || !matchId) return; // shouldn't really happen

  yield put(setChangingUserTeam({ changing: true, userId }));

  try {
    const { data } = yield call(consistentlyRequest, updateLobbyChangeTeam, {
      matchId,
      teamId: mapToApiTeam(teamId),
      userId,
    });

    yield put(setData(data));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.changeUserTeam'));
  } finally {
    yield put(setChangingUserTeam({ changing: false, userId }));
  }
}

export function* watchChangeUserTeam() {
  yield takeEvery(changeUserTeamAction.toString(), function* ({
    payload: { id: userId, teamId },
  }: ReturnType<typeof changeUserTeamAction>) {
    yield call(changeUserTeam, userId, teamId);
  });
}
