import { map, min, max, get } from 'lodash';

import * as api from '../../../apis/server/types';
import { Team, Map } from '../types';

import i18n from '../../../i18n';

const BASE_URL = process.env.REACT_APP_BASE_LOBBY_URL;

const MINIMUM_PLAYERS_PER_TEAM = 1;
const MAXIMUM_PLAYERS_PER_TEAM = 5;

const getUnstartableMapReasons = (teams: Team[], currentMap: Map) => {
  const teamUserCounts = map(teams, (team) => team.users.length);
  const smallestCount = min(teamUserCounts) || 0;
  const largestCount = max(teamUserCounts) || 0;

  const minPlayers = get(
    currentMap,
    'constraints.playerCount.min',
    MINIMUM_PLAYERS_PER_TEAM
  );
  const maxPlayers = get(
    currentMap,
    'constraints.playerCount.max',
    MAXIMUM_PLAYERS_PER_TEAM
  );

  if (smallestCount < minPlayers)
    return [
      i18n.t('platform.lobby.unstartableReasons.tooLittlePlayers', {
        count: minPlayers,
      }),
    ];

  if (largestCount > maxPlayers)
    return [
      i18n.t('platform.lobby.unstartableReasons.tooManyPlayers', {
        count: maxPlayers,
      }),
    ];

  return [];
};

export const getUnstartableReasons = (
  isUserAdmin: boolean,
  teams: Team[],
  currentMap: Map
) => {
  const reasons = getUnstartableMapReasons(teams, currentMap);

  if (!isUserAdmin)
    reasons.push(i18n.t('platform.lobby.unstartableReasons.notAdmin'));

  return reasons;
};

export const isUnstartable = (unstartableReasons: string[]) =>
  unstartableReasons.length > 0;

export const getMap = (
  { map, mapType }: api.Lobby,
  availableMaps: Map[]
): Map => {
  return (
    availableMaps.find(({ map: mapId, extra }) => {
      if (map !== mapId) return false;

      if (!mapType && !extra.mapType) return true;

      return mapType === extra.mapType;
    }) || availableMaps[0]
  );
};

export const getUrl = (shortId: string) => `${BASE_URL}/${shortId}`;
