import { call, put, takeEvery } from 'redux-saga/effects';

import { CLEAR_DATA } from '../actionTypes';
import {
  clearRedirectPath,
  clearToken,
  clearTokenData,
  clearUserData,
} from '../actions';
import persistState from '../../../store/meta/sagas/persistState';

export function* watchClearData() {
  yield takeEvery(CLEAR_DATA, clearData);
}

export default function* clearData() {
  yield put(clearRedirectPath());
  yield put(clearToken());
  yield put(clearTokenData());
  yield put(clearUserData());
  yield call(persistState);
}
