import React from 'react';
import { ListGroupItem, UncontrolledTooltip } from 'reactstrap';
import { useFela } from 'react-fela';

export type User = {
  id: string;
  avatar: string;
  name: string;
};

export type ItemProps = {
  leftTeam: User[];
  rightTeam: User[];
  map: string;
  lobbyId: string;
};

export default function Item({ leftTeam, rightTeam, map, lobbyId }: ItemProps) {
  const { css } = useFela();
  const mapNameId = `map-name-${lobbyId}`;
  const renderUsers = (users: User[]) =>
    users.map(({ id, avatar, name }) => (
      <img
        key={id}
        className={css(style.avatarImage)}
        alt={name}
        src={avatar}
      />
    ));

  return (
    <ListGroupItem
      tag='a'
      action
      href={`/${lobbyId}`}
      className={css(style.listGroupItem)}
    >
      <div className='d-flex flex-row justify-content-center'>
        <div className='mx-1'>{renderUsers(leftTeam)}</div>
        <div id={mapNameId} className={`mx-1 ${css(style.mapName)}`}>
          {map}
        </div>
        <UncontrolledTooltip target={mapNameId} trigger='hover'>
          {map}
        </UncontrolledTooltip>
        <div className='mx-1'>{renderUsers(rightTeam)}</div>
      </div>
    </ListGroupItem>
  );
}

const style = {
  listGroupItem: {
    padding: '0.65em 0.4em',
  },
  avatarImage: {
    borderRadius: '.4em',
    maxHeight: '1.35em',
    margin: '0 .10em',
  },
  mapName: {
    maxWidth: '20%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
} as const;
