import { get, filter, isEmpty, maxBy } from 'lodash';

import { User } from '../apis/server';
import badgeInfo from '../app/helpers/badgeInfo';

export const getUserBadgeInfo = (user: Pick<User, 'badges'>) => {
  const badges = get(user, 'badges', []);
  const knownBadges = filter(badges, isBadgeKnown);

  const hasBadge = !isEmpty(knownBadges);
  if (!hasBadge) return { hasBadge, badge: undefined };

  const priorityBadge = maxBy(knownBadges, 'priority');

  return {
    hasBadge,
    badge: priorityBadge,
  };
};
export const isBadgeKnown = ({ name }: { name: string }) => name in badgeInfo;
