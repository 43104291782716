import {
  CHANGE_NOTIFY_ON_LOBBY_READY,
  SET_NOTIFICATION_PERMISSIONS,
  SET_NOTIFY_ON_LOBBY_READY,
} from './actionTypes';

export const changeNotifyOnLobbyReady = ({ notifyOnLobbyReady }) => ({
  type: CHANGE_NOTIFY_ON_LOBBY_READY,
  payload: { notifyOnLobbyReady },
});

export const setNotifyOnLobbyReady = ({ notifyOnLobbyReady }) => ({
  type: SET_NOTIFY_ON_LOBBY_READY,
  payload: { notifyOnLobbyReady },
});

export const setNotificationPermissions = ({ notificationPermissions }) => ({
  type: SET_NOTIFICATION_PERMISSIONS,
  payload: { notificationPermissions },
});
