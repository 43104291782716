import { getUserBadgeInfo } from '../../../utils/badge';
import { getUserData } from '../../../authentication/store/selectors';

export const getBadgeInfo = (state) => {
  const { hasBadge: userHasBadge, badge: userBadge } = getUserBadgeInfo(
    getUserData(state)
  );

  return {
    userHasBadge,
    userBadge,
  };
};
