import LogRocket from 'logrocket';

export default () =>
  LogRocket.reduxMiddleware({
    stateSanitizer: (state) => {
      return {
        ...state,
        meta: undefined,
        router: undefined,
      };
    },
  });
