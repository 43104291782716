import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Lobby as ApiLobby } from '../../../apis/server/types';
import { MatchRating, Team } from '../types';

export interface LobbyState {
  aborting: boolean;
  changingMap: boolean;
  changingPlayerTeamChangesBlocked: boolean;
  changingTeam: boolean;
  changingUserTeam: Record<string, boolean>;
  changingTeamName: Record<Team['id'], boolean>;
  creating: boolean;
  data?: ApiLobby;
  fetching: boolean;
  joinError?: { error: string };
  joining: boolean;
  leaving: boolean;
  loading: boolean;
  localRating?: MatchRating;
  removingUser: Record<string, boolean>;
  startingMatch: boolean;
  subscribed: boolean;
  updatingRating: boolean;
}

export const initialState: LobbyState = {
  aborting: false,
  changingMap: false,
  changingPlayerTeamChangesBlocked: false,
  changingTeam: false,
  changingTeamName: { left: false, right: false, spec: false },
  changingUserTeam: {},
  creating: false,
  data: undefined,
  fetching: false,
  joinError: undefined,
  joining: true,
  leaving: false,
  removingUser: {},
  startingMatch: false,
  subscribed: false,
  loading: false,
  localRating: undefined,
  updatingRating: false,
};

const slice = createSlice({
  name: 'lobby',
  initialState,
  reducers: {
    clearData: (state) => {
      state.data = undefined;
    },
    clearJoinError: (state) => {
      state.joinError = undefined;
    },
    reset: () => initialState,
    setAborting: (
      state,
      { payload: aborting }: PayloadAction<LobbyState['aborting']>
    ) => {
      state.aborting = aborting;
    },
    setChangingMap: (
      state,
      { payload: changingMap }: PayloadAction<LobbyState['changingMap']>
    ) => {
      state.changingMap = changingMap;
    },
    setChangingTeam: (
      state,
      { payload: changingTeam }: PayloadAction<LobbyState['changingMap']>
    ) => {
      state.changingTeam = changingTeam;
    },
    updateChangingTeamName: (
      state,
      {
        payload: changingTeamName,
      }: PayloadAction<Partial<LobbyState['changingTeamName']>>
    ) => ({
      ...state,
      changingTeamName: {
        ...state.changingTeamName,
        ...changingTeamName,
      },
    }),
    setChangingUserTeam: (
      state,
      {
        payload: { changing, userId },
      }: PayloadAction<{ changing: boolean; userId: string }>
    ) => {
      state.changingUserTeam[userId] = changing;
    },
    setCreating: (
      state,
      { payload: creating }: PayloadAction<LobbyState['creating']>
    ) => {
      state.creating = creating;
    },
    setData: (state, { payload: data }: PayloadAction<LobbyState['data']>) => {
      state.data = data;
    },
    setFetching: (
      state,
      { payload: fetching }: PayloadAction<LobbyState['fetching']>
    ) => {
      state.fetching = fetching;
    },
    setJoinError: (
      state,
      { payload: joinError }: PayloadAction<LobbyState['joinError']>
    ) => {
      state.joinError = joinError;
    },
    setJoining: (
      state,
      { payload: joining }: PayloadAction<LobbyState['joining']>
    ) => {
      state.joining = joining;
    },
    setLeaving: (
      state,
      { payload: leaving }: PayloadAction<LobbyState['leaving']>
    ) => {
      state.leaving = leaving;
    },
    setLoading: (
      state,
      { payload: loading }: PayloadAction<LobbyState['loading']>
    ) => {
      state.loading = loading;
    },
    setLocalRating: (
      state,
      { payload: localRating }: PayloadAction<LobbyState['localRating']>
    ) => {
      state.localRating = localRating;
    },
    setRemovingUser: (
      state,
      {
        payload: { removing, userId },
      }: PayloadAction<{ removing: boolean; userId: string }>
    ) => {
      state.removingUser[userId] = removing;
    },
    setStartingMatch: (
      state,
      { payload: startingMatch }: PayloadAction<LobbyState['startingMatch']>
    ) => {
      state.startingMatch = startingMatch;
    },
    setSubscribed: (
      state,
      { payload: subscribed }: PayloadAction<LobbyState['subscribed']>
    ) => {
      state.subscribed = subscribed;
    },
    setUpdatingRating: (
      state,
      { payload: updatingRating }: PayloadAction<LobbyState['updatingRating']>
    ) => {
      state.updatingRating = updatingRating;
    },
    setChangingPlayerTeamChangesBlocked: (
      state,
      {
        payload: blocked,
      }: PayloadAction<LobbyState['changingPlayerTeamChangesBlocked']>
    ) => {
      state.changingPlayerTeamChangesBlocked = blocked;
    },
  },
});
export default slice;

export const abort = createAction('lobby/abort');
export const changeMap = createAction<{ id: string }>('lobby/changeMap');
export const changeTeam = createAction<{ id: Team['id'] }>('lobby/changeTeam');
export const changeUserTeam = createAction<{ id: string; teamId: Team['id'] }>(
  'lobby/changeUserTeam'
);
export const create = createAction('lobby/create');
export const fetchData = createAction<{ id: string }>('lobby/fetchData');
export const join = createAction<{ id: string }>('lobby/join');
export const joined = createAction<{ data: LobbyState['data'] }>(
  'lobby/joined'
);
export const joinFromCodeInput = createAction<{ code: string }>(
  'lobby/joinFromCodeInput'
);
export const leave = createAction('lobby/leave');
export const pageLoaded = createAction<{ lobbyId: string }>('lobby/pageLoaded');
export const pageUnloaded = createAction('lobby/pageUnloaded');
export const updateRating = createAction<{ rating: LobbyState['localRating'] }>(
  'lobby/updateRating'
);
export const removeUser = createAction<{ id: string }>('lobby/removeUser');
export const startMatch = createAction('lobby/startMatch');
export const subscribe = createAction<{ matchId: string }>('lobby/subscribe');
export const unsubscribe = createAction('lobby/unsubscribe');
export const changeTeamName = createAction<{
  teamId: Team['id'];
  name: string;
}>('lobby/changeTeamName');
export const changePlayerTeamChangesBlocked = createAction<boolean>(
  'lobby/changePlayerTeamChangesBlocked'
);
export const {
  clearData,
  clearJoinError,
  reset,
  setAborting,
  setChangingMap,
  setChangingTeam,
  setChangingUserTeam,
  setCreating,
  setData,
  setFetching,
  setJoinError,
  setJoining,
  setLeaving,
  setLoading,
  setLocalRating,
  setRemovingUser,
  setStartingMatch,
  setSubscribed,
  setUpdatingRating,
  updateChangingTeamName,
  setChangingPlayerTeamChangesBlocked,
} = slice.actions;
