import React from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';

import { isAuthenticated, getToken } from '../authentication/store/selectors';
import { hasExpired } from '../authentication/utils/token';

const AUTHENTICATED_REDIRECT = '/home';

export function HomeRedirect({ children, isAuthenticated }) {
  const location = useLocation();

  if (isAuthenticated) {
    return <Redirect to={AUTHENTICATED_REDIRECT} from={location} />;
  }

  return <>{children}</>;
}
HomeRedirect.propTypes = {
  isAuthenticated: bool,
};

const isValidlyAuthenticated = (state) =>
  isAuthenticated(state) && !hasExpired(getToken(state));

const mapStateToProps = (state) => ({
  isAuthenticated: isValidlyAuthenticated(state),
});
export default connect(mapStateToProps, null)(HomeRedirect);
