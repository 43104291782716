import {
  SET_NOTIFICATION_PERMISSIONS,
  SET_NOTIFY_ON_LOBBY_READY,
} from './actionTypes';

export const initialState = {
  notifyOnLobbyReady: false,
  notificationPermissions: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFY_ON_LOBBY_READY:
      return setNotifyOnLobbyReady(state, action);
    case SET_NOTIFICATION_PERMISSIONS:
      return setNotificationPermissions(state, action);
    default:
      return state;
  }
};

const setNotificationPermissions = (state, action) => {
  const {
    payload: { notificationPermissions },
  } = action;

  return {
    ...state,
    notificationPermissions,
  };
};

const setNotifyOnLobbyReady = (state, action) => {
  const {
    payload: { notifyOnLobbyReady },
  } = action;

  return {
    ...state,
    notifyOnLobbyReady,
  };
};
