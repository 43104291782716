import { get, find } from 'lodash';
import { Lobby, Player, PlayerTeam } from '../../../apis/server';
import availableMaps from '../../lobby/helpers/available-maps';

const TEAMS = {
  left: 'TEAM_1',
  right: 'TEAM_2',
} as const;

export const mapLobby = ({ lobbyIdentifier, map, players }: Lobby) => ({
  lobbyId: lobbyIdentifier,
  map: getLobbyMap(map),
  leftTeam: mapTeam(players, TEAMS.left),
  rightTeam: mapTeam(players, TEAMS.right),
});

const mapTeam = (players: Player[], team: PlayerTeam) =>
  players.filter((player) => player.team === team).map(mapPlayer);

const mapPlayer = (player: Player) => ({
  id: get(player, 'user._id'),
  avatar: get(player, 'user.avatar.small', ''),
  name: get(player, 'user.nickname', ''),
});
const getLobbyMap = (id: string) => {
  const mapEntry = find(availableMaps, { id });
  return get(mapEntry, 'name', '?');
};
