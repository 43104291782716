import React from 'react';
import { Modal, ModalBody, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const HOME_PATH = '/';

type ErrorModalProps = {
  backdrop?: boolean | 'static';
  className?: string;
  open?: boolean;
  keyboard?: boolean;
  error: string;
};

export default function ErrorModal({
  backdrop = 'static',
  className = '',
  open = false,
  keyboard = false,
  error,
}: ErrorModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      backdrop={backdrop}
      className={className}
      isOpen={open}
      keyboard={keyboard}
    >
      <ModalBody>
        <Container>
          <h4 className='mt-2 mb-4'>
            {t('platform.lobby.errors.modal.heading')}
          </h4>
          <p className='my-2'>{t(`platform.lobby.errors.codes.${error}`)}</p>
          <div className='mt-4 mb-2 d-flex justify-content-end'>
            <a className='btn btn-success' href={HOME_PATH}>
              {t('platform.lobby.errors.modal.goBackHome')}
            </a>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
}
