import React from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

import { User } from '../../types';
import UserList from '../user-list';
import TeamName from './team-name';

type TeamProps = {
  className?: string;
  joinable?: boolean;
  joining?: boolean;
  name: string;
  nameEditable?: boolean;
  nameLoading?: boolean;
  onJoin?: () => void;
  onRemoveUser?: (id: string) => void;
  onSpecUser?: (id: string) => void;
  onSwapUser?: (id: string) => void;
  onNameChange?: (name: string) => void;
  reverse?: boolean;
  users?: User[];
};

export default function Team({
  className = '',
  joinable = false,
  joining = false,
  name,
  nameLoading = false,
  onJoin = () => {},
  onRemoveUser = () => {},
  onSpecUser = () => {},
  onSwapUser = () => {},
  onNameChange = () => {},
  reverse = false,
  users = [],
  nameEditable = false,
}: TeamProps) {
  const { css } = useFela({ reverse });
  const { t } = useTranslation();

  const joinText = joining
    ? 'platform.lobby.team.joining'
    : 'platform.lobby.team.join';

  return (
    <div className={className}>
      <TeamName
        name={name}
        editable={nameEditable}
        onChange={onNameChange}
        reverse={reverse}
        loading={nameLoading}
      />
      <div
        className={css(({ reverse }) => ({
          display: 'flex',
          flexDirection: reverse ? 'row' : 'row-reverse',
          margin: '.5em 0',
          minHeight: '38px',
        }))}
      >
        {joinable && (
          <Button onClick={onJoin} outline disabled={joining}>
            {joining && <Spinner size='sm' />} {t(joinText)}{' '}
            <FontAwesomeIcon icon={faSignInAlt} />
          </Button>
        )}
      </div>
      <UserList
        onRemove={onRemoveUser}
        onSwap={onSwapUser}
        onSpec={onSpecUser}
        users={users}
        reverse={reverse}
      />
    </div>
  );
}
