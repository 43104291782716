import spawnAllSagas from '../../../utils/spawnAllSagas';

import { watchCheck } from './check';
import { watchClearData } from './clearData';
import { watchTokenReceived } from './tokenReceived';
import { watchFetchUserData } from './fetchUserData';

export default spawnAllSagas([
  watchCheck,
  watchClearData,
  watchFetchUserData,
  watchTokenReceived,
]);
