import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { mapToApiTeam } from '../../presenters/team';
import { Team } from '../../types';
import { getLobbyId } from '../selectors';
import { updateLobbyChangeMap } from '../../../../apis/server';
import i18n from '../../../../i18n';
import consistentlyRequest from '../../../../utils/consistentlyRequest';

import {
  changeTeamName as changeTeamNameAction,
  setData,
  updateChangingTeamName,
} from '../slice';

export default function* changeTeamName(teamId: Team['id'], name: string) {
  const team = mapToApiTeam(teamId);

  if (team === 'SPEC') return; // makes no sense.

  const matchId: string | undefined = yield select(getLobbyId);
  if (!matchId) return; // also makes no sense.

  yield put(updateChangingTeamName({ [teamId]: true }));
  try {
    const parameterName = team === 'TEAM_1' ? 'team1Name' : 'team2Name';
    const { data } = yield call(consistentlyRequest, updateLobbyChangeMap, {
      matchId,
      [parameterName]: name,
    });

    yield put(setData(data));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.changeTeamName'));
  } finally {
    yield put(updateChangingTeamName({ [teamId]: false }));
  }
}

export function* watch() {
  yield takeEvery(changeTeamNameAction.toString(), function* ({
    payload: { teamId, name },
  }: ReturnType<typeof changeTeamNameAction>) {
    yield call(changeTeamName, teamId, name);
  });
}
