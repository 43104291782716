import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { compact } from 'lodash';

import { blacklistPersistence as blacklistApp } from '../app/store';
import { blacklistPersistence as blacklistAuthentication } from '../authentication/store';
import { blacklistPersistence as blacklistMeta } from './meta';
import reducer from './reducer';

const config = {
  key: 'root',
  blacklist: compact([
    'router',
    blacklistMeta && 'meta',
    blacklistAuthentication && 'authentication',
    blacklistApp && 'app',
  ]),
  storage,
};

export default persistReducer(config, reducer);
