import React from 'react';

import { User } from '../../types';
import UserItem from './user-item';

type SpectatorsProps = {
  users?: User[];
  onRemove?: (userId: string) => void;
  onMove?: (userId: string, teamId: 'left' | 'right') => void;
};

export default function Spectators({
  users = [],
  onRemove = () => {},
  onMove = () => {},
}: SpectatorsProps) {
  return (
    <div className='d-flex'>
      {users.map(
        ({ avatarUrl, admin, id, name, removable, swappable, tier }) => (
          <UserItem
            admin={admin}
            avatarUrl={avatarUrl}
            name={name}
            key={id}
            userId={id}
            movable={swappable}
            onMove={(teamId) => onMove(id, teamId)}
            onRemove={() => onRemove(id)}
            removable={removable}
            tier={tier}
          />
        )
      )}
    </div>
  );
}
