import { compose, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';

import history from '../history';
import persistedReducer from './persistedReducer';
import rootSaga from './sagas';
import { setPersistor } from './meta/actions';
import { logRocket } from './middleware';

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) ||
  compose;

export default () => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = applyMiddleware(
    routerMiddleware(history),
    sagaMiddleware,
    logRocket()
  );
  const enhancers = composeEnhancers(middleware);
  const store = createStore(persistedReducer, enhancers);
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  store.dispatch(setPersistor({ persistor }));

  return {
    persistor,
    store,
  };
};
