import { call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { REDIRECT_TO_PLAN_CHECKOUT } from '../actionTypes';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import { createCheckoutSession } from '../../../../apis/membership';
import { getStripe } from './helpers/stripe';
import i18n from '../../../../i18n';
import { getUserMembershipTier } from '../selectors/user';
import manageSubscription from './manageSubscription';
import { setLoadingCheckout } from '../actions';
import { getCouponCode, getDiscountInfo } from '../../hooks/useUrlCouponInfo';

export default function* redirectToPlanCheckout({ plan }) {
  const currentPlan = yield select(getUserMembershipTier);

  if (plan === currentPlan) return;

  if (plan === 'free') {
    alert(i18n.t('membership.subscriptions.downgradeNoticeAlert'));
    yield call(manageSubscription);
    return;
  }

  const coupon = getCouponCode();
  const discountInfo = yield call(getDiscountInfo, coupon);
  const discountCode = !!discountInfo.discount ? coupon : undefined;

  yield put(setLoadingCheckout({ loading: true }));
  try {
    const { data: session } = yield call(
      consistentlyRequest,
      createCheckoutSession,
      { discountCode }
    );
    const stripe = yield call(getStripe);
    stripe.redirectToCheckout({
      sessionId: session.id,
    });
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('membership.errors.subscriptionRedirectionError'));
  }
  yield put(setLoadingCheckout({ loading: false }));
}

export function* watch() {
  yield takeEvery(REDIRECT_TO_PLAN_CHECKOUT, function* ({ payload }) {
    yield call(redirectToPlanCheckout, payload);
  });
}
