import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

import {
  getOptionsDisabled,
  getPlayerTeamChangesBlocked,
  getTeamNames,
  getUserPlayer,
} from '../store/selectors/lobby';
import { isFinished, isFinishing } from '../store/selectors/page';
import {
  getChangingMap,
  getChangingPlayerTeamChangesBlocked,
  getChangingTeam,
  getChangingTeamName,
  getData,
  getLeaving,
  getLobbyShortId,
  getStartingMatch,
} from '../store/selectors';
import { getMap, getUnstartableReasons, getUrl } from '../presenters/lobby';
import {
  changeMap,
  changeTeam,
  changeTeamName,
  changeUserTeam,
  leave,
  removeUser,
  startMatch,
  changePlayerTeamChangesBlocked,
} from '../store/slice';

import availableMaps from '../helpers/available-maps';

import Room from '../components/room';
import overlay from '../../../utils/redux/overlay';
import { getTeams as mapTeams } from '../presenters/team';
import { isAdmin } from '../presenters/player';
import { Team } from '../types';
import { getCurrent as getCurrentMap } from '../presenters/map';

const makeEmptyTeam = ({
  id = 'left',
  reverse = false,
  ...rest
}: Partial<Team>): Team => ({
  id,
  reverse,
  name: '?',
  users: [],
  joinable: false,
  nameEditable: false,
  ...rest,
});

const teams = createSelector(
  getData,
  getUserPlayer,
  getTeamNames,
  getChangingTeamName,
  (
    data,
    userPlayer,
    teamNames,
    changingTeamName
  ): {
    left: Team;
    right: Team;
    spec: Team;
  } =>
    !!data
      ? mapTeams(data, userPlayer, teamNames, changingTeamName)
      : {
          left: makeEmptyTeam({ id: 'left' }),
          right: makeEmptyTeam({ id: 'right', reverse: true }),
          spec: makeEmptyTeam({ id: 'spec' }),
        }
);

const spectatable = createSelector(
  getUserPlayer,
  (userPlayer) => userPlayer?.team !== 'SPEC'
);

const unstartableReasons = createSelector(
  getData,
  getUserPlayer,
  teams,
  (data, userPlayer, teams) => {
    if (!data || !userPlayer) return undefined;

    const currentMap = getMap(data, availableMaps);

    return getUnstartableReasons(
      isAdmin(userPlayer),
      [teams.left, teams.right],
      currentMap
    );
  }
);

const unstartable = createSelector(
  unstartableReasons,
  (reasons) => !isEmpty(reasons)
);

export default connect(
  overlay({
    availableMaps: () => availableMaps,
    changingMap: getChangingMap,
    changingTeams: getChangingTeam,
    currentMap: (state) =>
      getCurrentMap(getData(state)?.map || '', getData(state)?.mapType),
    finished: isFinished,
    finishing: isFinishing,
    leaving: getLeaving,
    optionsDisabled: getOptionsDisabled,
    spectatable,
    starting: getStartingMatch,
    teams,
    unstartableReasons,
    unstartable,
    url: (state) => getUrl(getLobbyShortId(state) || ''),
    changingPlayerTeamChangesBlocked: getChangingPlayerTeamChangesBlocked,
    playerTeamChangesBlocked: getPlayerTeamChangesBlocked,
  }),
  (dispatch) => ({
    onJoinTeam: (id: Team['id']) => dispatch(changeTeam({ id })),
    onLeave: () => dispatch(leave()),
    onMapChange: (id: string) => dispatch(changeMap({ id })),
    onPlayerTeamChangesBlockedChange: (blocked: boolean) =>
      dispatch(changePlayerTeamChangesBlocked(blocked)),
    onRemoveUser: (id: string) => dispatch(removeUser({ id })),
    onStart: () => dispatch(startMatch()),
    onChangeUserTeam: (id: string, teamId: Team['id']) =>
      dispatch(changeUserTeam({ id, teamId })),
    onTeamNameChange: (teamId: Team['id'], name: string) =>
      dispatch(changeTeamName({ teamId, name })),
  })
)(Room);
