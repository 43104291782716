import React, { useRef } from 'react';
import { uniqBy } from 'lodash';
import { useFela } from 'react-fela';
import { Spinner } from 'reactstrap';

import Item from '../components/item';
import useInfiniteScroll from '../hooks/use-infinite-scroll';
import useHistory from '../hooks/use-history';
import { mapLobby } from '../presenters/page';
import EmptyState from '../components/empty-state';

export type HistoryProps = {
  className?: string;
};

export default function History({ className = '' }: HistoryProps) {
  const { css } = useFela();

  // TODO: this looks like it's going to give me a lot of headache sometime
  const loadingRef = useRef<HTMLDivElement>(null);
  const { fetchNextPage, history, hasNext } = useHistory({
    pageSize: 15,
  });
  useInfiniteScroll(
    {
      element: loadingRef.current,
      onScroll: (entities, observer) => {
        if (!entities[0].isIntersecting) return;
        fetchNextPage();
      },
    },
    [fetchNextPage]
  );
  const crossYears =
    uniqBy(history, ({ creationDay }) => creationDay.getFullYear()).length > 1;

  const hasNoMatches = !hasNext && history.length === 0;
  if (hasNoMatches) return <EmptyState />;

  return (
    <div className={`${css(style.container)} ${className}`}>
      {history.map(({ lobbies, creationDay }) => {
        const dateHeader = creationDay.toLocaleDateString(undefined, {
          year: crossYears ? 'numeric' : undefined,
          month: 'long',
          day: 'numeric',
        });

        return (
          <div className={css(style.day)} key={creationDay.toISOString()}>
            <h4 className={`${css(style.dayHeader)} bg-white`}>{dateHeader}</h4>
            <div className={css(style.lobbyList)}>
              {lobbies.map((lobby) => {
                const { leftTeam, lobbyId, map, rightTeam } = mapLobby(lobby);

                return (
                  <div className={css(style.itemContainer)} key={lobbyId}>
                    <Item
                      leftTeam={leftTeam}
                      lobbyId={lobbyId}
                      map={map}
                      rightTeam={rightTeam}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {hasNext && (
        <div className={css(style.spinnerContainer)} ref={loadingRef}>
          <Spinner size='sm' />
        </div>
      )}
    </div>
  );
}

const style = {
  container: {},
  day: {},
  itemContainer: {
    margin: '0.2em',
    borderRadius: '.4em',
  },
  dayHeader: {
    fontSize: '1.5em',
    position: 'sticky',
    top: '0px',
    zIndex: 20,
  },
  lobbyList: {
    margin: '0.4em 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    margin: '1em 0',
  },
} as const;
