import { takeEvery, take, call, select, put } from 'redux-saga/effects';
import { EventChannel } from 'redux-saga';

import { setSubscribed, subscribe as subscribeAction } from '../slice';
import { getToken } from '../../../../authentication/store/selectors';
import { getSubscribed } from '../selectors';

import subscribeToSocket, { EventType } from './socket/channel';
import messageHandler from './socket/messageHandler';

export default function* subscribe(matchId: string) {
  const token: string | undefined = yield select(getToken);
  if (!token) {
    // really shouldn't happen
    console.error('trying to subscribe with no token!');
    return;
  }

  const channel: EventChannel<EventType> = yield call(subscribeToSocket, {
    token,
    matchId,
  });

  yield put(setSubscribed(true));

  try {
    while ((yield select(getSubscribed)) as boolean) {
      const event: EventType = yield take(channel);
      yield call(messageHandler, event);
    }
  } catch (error) {
    console.log('error while reading websocket messages:');
    console.error(error);
  }
}

export function* watch() {
  yield takeEvery(subscribeAction.toString(), function* ({
    payload: { matchId },
  }: ReturnType<typeof subscribeAction>) {
    yield call(subscribe, matchId);
  });
}
