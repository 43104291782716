import React, { useState } from 'react';
import { useFela } from 'react-fela';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { Placement } from 'popper.js';

type ActionIconProps = {
  className?: string;
  clickable?: boolean;
  icon: any;
  id?: string;
  onClick?: () => void;
  tooltipPlacement?: Placement;
  tooltipText: string;
  color?: string;
};

export default function ActionIcon({
  className = '',
  clickable = false,
  icon,
  id,
  onClick = () => {},
  tooltipPlacement = 'top',
  tooltipText,
  color,
}: ActionIconProps) {
  const { css } = useFela({ clickable });
  const [iconId] = useState(id || generateId);
  const colorClass = color && `text-${color}`;

  const cssClasses = css(({ clickable }) => ({
    cursor: clickable ? 'pointer' : 'auto',
    display: 'inline',
  }));

  return (
    <div className={`${className} ${cssClasses}`}>
      <FontAwesomeIcon
        className={colorClass}
        icon={icon}
        id={iconId}
        onClick={onClick}
      />
      <UncontrolledTooltip placement={tooltipPlacement} target={iconId}>
        {tooltipText}
      </UncontrolledTooltip>
    </div>
  );
}

const generateId = () => `ai-${uuid()}`;
