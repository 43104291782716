import { call, put, takeEvery, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  setData,
  setRemovingUser,
  removeUser as removeUserAction,
} from '../slice';
import { getLobbyId } from '../selectors';
import { updateLobbyKick } from '../../../../apis/server';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import i18n from '../../../../i18n';

export default function* removeUser(userId: string) {
  const matchId: string = yield select(getLobbyId);

  yield put(setRemovingUser({ removing: true, userId }));

  try {
    const { data } = yield call(consistentlyRequest, updateLobbyKick, {
      matchId,
      userId,
    });

    yield put(setData(data));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.removeUser'));
  } finally {
    yield put(setRemovingUser({ removing: false, userId }));
  }
}

export function* watchRemoveUser() {
  yield takeEvery(removeUserAction.toString(), function* ({
    payload: { id },
  }: ReturnType<typeof removeUserAction>) {
    yield call(removeUser, id);
  });
}
