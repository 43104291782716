import React from 'react';
import { RendererProvider } from 'react-fela';
import { Provider as ReduxProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import 'simplebar';
import 'simplebar/dist/simplebar.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.min.css';

import i18n from './i18n';
import renderer from './fela/renderer';
import { store, persistor } from './store';
import history from './history';

import App from './app/App';
import LandingPage from './pages/landing';
import Loading from './components/Loading';
import AuthenticationPage from './authentication/Page';
import Authenticated from './authentication/utils/Authenticated';
import HomeRedirect from './containers/HomeRedirect';

import './App.scss';

export default () => {
  return (
    <ApplicationProvider>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path='/jwt'>
            <AuthenticationPage />
          </Route>
          <Route path='/:id'>
            <Authenticated>
              <App />
            </Authenticated>
          </Route>
          <Route path='/'>
            <HomeRedirect>
              <LandingPage />
            </HomeRedirect>
          </Route>
        </Switch>
      </ConnectedRouter>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </ApplicationProvider>
  );
};

const ApplicationProvider = ({ children }) => (
  <RendererProvider renderer={renderer}>
    <ReduxProvider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
      </PersistGate>
    </ReduxProvider>
  </RendererProvider>
);
