export interface ValidationItemResult {
  type: 'in_range' | 'match_regex';
  successful: boolean;
  meta: any;
}

const VALID_NAME_REGEX = /^[\p{Script=Latin}\d ]+$/u;

const VALIDATIONS: ((name: string, meta?: any) => ValidationItemResult)[] = [
  (name) => {
    const minimum = 2,
      maximum = 15;

    return {
      type: 'in_range',
      successful: name.length > minimum && name.length < maximum,
      meta: {
        maximum,
        minimum,
      },
    };
  },
  (name) => {
    const successful = VALID_NAME_REGEX.test(name);
    return {
      type: 'match_regex',
      successful,
      meta: {},
    };
  },
];

export type ValidationResult = {
  validations: ValidationItemResult[];
  successful: boolean;
  meta: any;
};

export default (name: string): ValidationResult => {
  const meta = {};

  const validations = VALIDATIONS.map((validation) => validation(name, meta));
  const successful = validations.every(({ successful }) => successful);

  return {
    validations,
    successful,
    meta,
  };
};
