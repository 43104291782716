import { useEffect, useState } from 'react';

import { Partner, getPartner } from '../../../../apis/partner';

const DEFAULT_PARTNER: Partner = {
  gender: 'female',
  name: 'Isa',
  url: 'https://twitch.tv/isasudati',
};

export default function usePartnerApi() {
  const [partner, setPartner] = useState<Partner | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (partner) return;

    setLoading(true);
    getPartner()
      .then(({ data: partner }) => setPartner(partner))
      .catch(() => setPartner(DEFAULT_PARTNER))
      .finally(() => setLoading(false));
  }, [partner]);

  return { loading, partner };
}
