import { get } from 'lodash';

import { AuthenticationState } from './reducer';

type State = any; // TODO:

export const getAuthentication = (state: State): AuthenticationState =>
  state.authentication;

export const getRedirectPath = (state: State) =>
  getAuthentication(state).redirectPath;

export const getToken = (state: State) => getAuthentication(state).token;

export const getTokenData = (state: State) =>
  getAuthentication(state).tokenData;

export const getUserData = (state: State) => getAuthentication(state).userData;

export const getUserNickname = (state: State) =>
  get(getUserData(state), 'nickname', undefined);

export const getUserAvatar = (state: State) =>
  get(getUserData(state), 'avatar', undefined);

export const getUserSmallAvatar = (state: State) =>
  get(getUserData(state), 'avatar.small', undefined);

export const getUserId = (state: State) =>
  get(getTokenData(state), 'userId', undefined);

export const getSteamId = (state: State) =>
  get(getTokenData(state), 'steamId', undefined);

export const hasRedirectPath = (state: State) => !!getRedirectPath(state);

export const hasToken = (state: State) => !!getToken(state);

export const hasTokenData = (state: State) => !!getTokenData(state);

export const isAuthenticated = (state: State) => hasToken(state);
