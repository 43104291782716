import React from 'react';
import { useTranslation } from 'react-i18next';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import PlanCard from './PlanCard';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

export default function MembershipPlanCard({
  current = false,
  downgrade = false,
  discount = false,
  discountAmount = 0,
  onSubscribe = () => {},
  price = 0,
  type = 'free',
  loading = false,
}) {
  const { t, i18n } = useTranslation();

  const disabled = current;
  const upgrade = !downgrade && !current;

  const currentPerksKey = `membership.subscriptions.${type}.perks`;
  const futurePerksKey = `membership.subscriptions.${type}.futurePerks`;

  const currentPerks = (i18n.exists(currentPerksKey)
    ? t(currentPerksKey, {
        returnObjects: true,
      })
    : []
  ).map(([perk, type]) => ({ icon: faCheck, text: perk, type }));
  const futurePerks = (i18n.exists(futurePerksKey)
    ? t(futurePerksKey, {
        returnObjects: true,
      })
    : []
  ).map(([perk, type]) => ({
    icon: faCalendar,
    text: perk,
    type,
  }));

  return (
    <PlanCard
      name={t(`membership.subscriptions.${type}.title`)}
      billingInterval={t('membership.subscriptions.month')}
      tagline={t(`membership.subscriptions.${type}.subtitle`)}
      price={price}
      buttonColor={(upgrade && 'primary') || 'secondary'}
      disabled={true || disabled}
      discount={discount}
      discountAmount={discountAmount}
      onSubscribe={onSubscribe}
      loading={loading}
      buttonText={t(
        (downgrade && 'membership.subscriptions.change') ||
          (current && 'membership.subscriptions.current') ||
          'membership.subscriptions.subscribe'
      )}
      perks={currentPerks.concat(futurePerks)}
    />
  );
}
