import React from 'react';
import { StyleFunction, useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';
import {
  faBan,
  faExchangeAlt,
  faMeteor,
  faStar,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';

import Avatar from '../../../components/Avatar';
import ActionIcon from '../action-icon';
import BadgedAvatar from '../../../components/BadgedAvatar';
import { IStyle } from 'fela';
import { UserBadge } from '../../../../apis/server';

const TIER_ICONS = {
  free: false,
  gold: faMeteor,
};

export default function User({
  admin = false,
  avatarUrl,
  badge,
  hasBadge = false,
  name,
  onRemove = () => {},
  onSwap = () => {},
  onSpec = () => {},
  removable = false,
  reverse = false,
  spectatable = false,
  swappable = false,
  tier = 'free',
}: {
  admin?: boolean;
  avatarUrl: string;
  badge?: UserBadge;
  hasBadge?: boolean;
  name: string;
  onRemove?: () => void;
  onSwap?: () => void;
  onSpec?: () => void;
  removable?: boolean;
  reverse?: boolean;
  spectatable?: boolean;
  swappable?: boolean;
  tier: 'free' | 'gold';
}) {
  const { css } = useFela({ reverse });
  const { t } = useTranslation();

  const tierIcon = TIER_ICONS[tier];

  return (
    <div className={css(containerStyle)}>
      {hasBadge ? (
        <BadgedAvatar
          alt={name}
          badge={badge}
          className={css(styles.avatar)}
          url={avatarUrl}
        />
      ) : (
        <Avatar alt={name} className={css(styles.avatar)} url={avatarUrl} />
      )}
      <span className={css(styles.item)}>{name}</span>
      {tierIcon && (
        <ActionIcon
          className={css(styles.item, styles.actionIcon)}
          icon={tierIcon}
          tooltipText={t(`platform.lobby.team.user.tiers.${tier}`)}
        />
      )}
      {admin && (
        <ActionIcon
          className={css(styles.item, styles.actionIcon)}
          icon={faStar}
          tooltipText={t('platform.lobby.team.user.admin')}
        />
      )}
      {removable && (
        <ActionIcon
          className={css(styles.item, styles.actionIcon)}
          clickable
          icon={faBan}
          onClick={onRemove}
          tooltipText={t('platform.lobby.team.user.remove')}
        />
      )}
      {swappable && (
        <ActionIcon
          className={css(styles.item, styles.actionIcon)}
          clickable
          icon={faExchangeAlt}
          onClick={onSwap}
          tooltipText={t('platform.lobby.team.user.swap')}
        />
      )}
      {spectatable && (
        <ActionIcon
          className={css(styles.item, styles.actionIcon)}
          clickable
          icon={faUserFriends}
          onClick={onSpec}
          tooltipText={t('platform.lobby.team.user.moveToSpec')}
        />
      )}
    </div>
  );
}

const styles: Record<string, IStyle> = {
  avatar: {
    margin: '0 .4em',
  },
  item: {
    margin: '0 .2em',
  },
  adminIcon: {
    color: '#ccc',
  },
  actionIcon: {
    color: '#aaa',
  },
};
const containerStyle: StyleFunction<{}, { reverse: boolean }> = ({
  reverse,
}) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: reverse ? 'row-reverse' : 'row',
});
