import { get } from 'lodash';

export default async <T extends (...params: any[]) => Promise<R>, R = any>(
  api: T,
  ...params: Parameters<T>
) => {
  const response = await api(...params);
  const isError = !!get(response, 'data.isError', false);

  if (isError) throw response;

  return response;
};
