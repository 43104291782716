import api from './axios';
import { StatField, UserStats } from './types';

export type TopLeaderboardResponse = {
  leaderboard: UserStats[];
  leaderboardUserPosition: number | null;
  userStats: UserStats | null;
  total: number;
};

export const getLeaderboardTop = ({ field }: { field: StatField }) =>
  api.get<TopLeaderboardResponse>(`/leaderboard/top?field=${field}`);
