import {
  MANAGE_SUBSCRIPTION,
  REDIRECT_TO_PLAN_CHECKOUT,
  SET_LOADING_CHECKOUT,
  SET_LOADING_SUBSCRIPTION_MANAGEMENT,
  WELCOME_PAGE_LOAD,
} from './actionTypes';

export const redirectToPlanCheckout = ({ plan }) => ({
  type: REDIRECT_TO_PLAN_CHECKOUT,
  payload: { plan },
});

export const manageSubscription = () => ({
  type: MANAGE_SUBSCRIPTION,
});

export const setLoadingCheckout = ({ loading }) => ({
  type: SET_LOADING_CHECKOUT,
  payload: { loading },
});

export const setLoadingSubscriptionManagement = ({ loading }) => ({
  type: SET_LOADING_SUBSCRIPTION_MANAGEMENT,
  payload: { loading },
});

export const welcomePageLoad = () => ({
  type: WELCOME_PAGE_LOAD,
})
