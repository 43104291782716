import decode from 'jwt-decode';
import moment from 'moment';

export default (token) => {
  const decoded = decode(token);
  const { exp: expiration } = decoded;
  const expirationDate = moment.unix(expiration).utc();
  const now = moment.utc();

  return expirationDate < now;
};
