import { call, put, select, takeEvery } from 'redux-saga/effects';

import { getUserById } from '../../../apis/server';
import consistentlyRequest from '../../../utils/consistentlyRequest';
import { getUserId } from '../selectors';
import { setUserData, setUserDataLoading } from '../actions';
import { FETCH_USER_DATA } from '../actionTypes';

export default function* fetchUserData() {
  const userId = yield select(getUserId);

  yield put(setUserDataLoading({ loading: true }));

  try {
    const { data: userData } = yield call(consistentlyRequest, getUserById, {
      userId,
    });

    yield put(setUserData({ userData }));
  } catch (error) {
    const {
      response: { message },
    } = error;

    console.error(message); // TODO: show in a useful manner
  } finally {
    yield put(setUserDataLoading({ loading: false }));
  }
}

export function* watchFetchUserData() {
  yield takeEvery(FETCH_USER_DATA, fetchUserData);
}
