import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18n from '../../../i18n';
import { CHANGE_NOTIFY_ON_LOBBY_READY } from '../actionTypes';
import { setNotificationPermissions, setNotifyOnLobbyReady } from '../actions';

export default function* changeNotifyOnLobbyReady({ notifyOnLobbyReady }) {
  let permission = Notification.permission;
  yield put(
    setNotificationPermissions({ notificationPermissions: permission })
  );

  if (permission !== 'granted') {
    permission = yield call(Notification.requestPermission);
    yield put(
      setNotificationPermissions({ notificationPermissions: permission })
    );

    if (permission !== 'granted') {
      toast.error(
        i18n.t('platform.settings.errors.notificationPermissionFailed')
      );
      return;
    }
  }

  yield put(setNotifyOnLobbyReady({ notifyOnLobbyReady }));
}

export function* watch() {
  yield takeEvery(CHANGE_NOTIFY_ON_LOBBY_READY, function* ({ payload }) {
    yield call(changeNotifyOnLobbyReady, payload);
  });
}
