import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { updateLobbyChangeMap } from '../../../../apis/server';
import i18n from '../../../../i18n';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import { getLobbyId } from '../selectors';

import {
  changePlayerTeamChangesBlocked as changePlayerTeamChangesBlockedAction,
  setChangingPlayerTeamChangesBlocked,
  setData,
} from '../slice';

export default function* changePlayerTeamChangesBlocked(blocked: boolean) {
  const matchId: string | undefined = yield select(getLobbyId);

  if (!matchId) return; // makes no sense

  yield put(setChangingPlayerTeamChangesBlocked(true));

  try {
    const { data } = yield call(consistentlyRequest, updateLobbyChangeMap, {
      matchId,
      isPlayerChangeTeamEnabled: !blocked,
    });
    yield put(setData(data));
  } catch (error) {
    console.error(error);
    toast.error(
      i18n.t('platform.lobby.errors.sagas.changePlayerTeamChangeBlocked')
    );
  } finally {
    yield put(setChangingPlayerTeamChangesBlocked(false));
  }
}

export function* watch() {
  yield takeEvery(changePlayerTeamChangesBlockedAction.toString(), function* ({
    payload: blocked,
  }: ReturnType<typeof changePlayerTeamChangesBlockedAction>) {
    yield call(changePlayerTeamChangesBlocked, blocked);
  });
}
