import { PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../apis/server';
import {
  CLEAR_REDIRECT_PATH,
  CLEAR_TOKEN,
  CLEAR_TOKEN_DATA,
  CLEAR_USER_DATA,
  SET_REDIRECT_PATH,
  SET_TOKEN,
  SET_TOKEN_DATA,
  SET_USER_DATA,
  SET_USER_DATA_LOADING,
} from './actionTypes';

export interface AuthenticationState {
  redirectPath?: string;
  token?: string;
  tokenData?: { userId: string; steamId: string };
  userData?: User;
  userDataLoading: boolean;
}

export const initialState = {
  redirectPath: undefined,
  token: undefined,
  tokenData: undefined,
  userData: undefined,
  userDataLoading: false,
};

export default (state: AuthenticationState = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_REDIRECT_PATH:
      return clearRedirectPath(state);
    case CLEAR_TOKEN:
      return clearToken(state);
    case CLEAR_TOKEN_DATA:
      return clearTokenData(state);
    case CLEAR_USER_DATA:
      return clearUserData(state);
    case SET_REDIRECT_PATH:
      return setRedirectPath(state, action);
    case SET_TOKEN:
      return setToken(state, action);
    case SET_TOKEN_DATA:
      return setTokenData(state, action);
    case SET_USER_DATA:
      return setUserData(state, action);
    case SET_USER_DATA_LOADING:
      return setUserDataLoading(state, action);
    default:
      return state;
  }
};

const clearRedirectPath = (state: AuthenticationState) => ({
  ...state,
  redirectPath: undefined,
});

const clearToken = (state: AuthenticationState) => ({
  ...state,
  token: undefined,
});

const clearTokenData = (state: AuthenticationState) => ({
  ...state,
  tokenData: undefined,
});

const clearUserData = (state: AuthenticationState) => ({
  ...state,
  userData: undefined,
});

const setRedirectPath = (
  state: AuthenticationState,
  action: PayloadAction<{ path?: string }>
) => {
  const {
    payload: { path: redirectPath },
  } = action;

  return {
    ...state,
    redirectPath,
  };
};

const setToken = (
  state: AuthenticationState,
  action: PayloadAction<{ token?: string }>
) => {
  const {
    payload: { token },
  } = action;

  return {
    ...state,
    token,
  };
};

const setTokenData = (
  state: AuthenticationState,
  action: PayloadAction<{ tokenData?: any }>
) => {
  const {
    payload: { tokenData },
  } = action;

  return {
    ...state,
    tokenData,
  };
};

const setUserData = (
  state: AuthenticationState,
  action: PayloadAction<{ userData?: User }>
) => {
  const {
    payload: { userData },
  } = action;

  return {
    ...state,
    userData,
  };
};

const setUserDataLoading = (
  state: AuthenticationState,
  action: PayloadAction<{ loading: string }>
) => {
  const {
    payload: { loading },
  } = action;

  return {
    ...state,
    userDataLoading: loading,
  };
};
