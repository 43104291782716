import { call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  setStartingMatch,
  setData,
  startMatch as startMatchAction,
} from '../slice';
import { getLobbyId } from '../selectors';
import { updateMatchStartServer } from '../../../../apis/server';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import i18n from '../../../../i18n';

export default function* startMatch() {
  const matchId: string | undefined = yield select(getLobbyId);

  yield put(setStartingMatch(true));

  try {
    const { data } = yield call(consistentlyRequest, updateMatchStartServer, {
      matchId,
    });

    yield put(setData(data));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.startMatch'));
  } finally {
    yield put(setStartingMatch(false));
  }
}

export function* watchStartMatch() {
  yield takeEvery(startMatchAction.toString(), startMatch);
}
