import {
  SET_LOADING_CHECKOUT,
  SET_LOADING_SUBSCRIPTION_MANAGEMENT,
} from './actionTypes';

const initialState = {
  loadingCheckout: false,
  loadingSubscriptionManagement: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_CHECKOUT:
      return setLoadingCheckout(state, action);
    case SET_LOADING_SUBSCRIPTION_MANAGEMENT:
      return setLoadingSubscriptionManagement(state, action);
    default:
      return state;
  }
};

const setLoadingCheckout = (
  state,
  { payload: { loading: loadingCheckout } }
) => ({
  ...state,
  loadingCheckout,
});

const setLoadingSubscriptionManagement = (
  state,
  { payload: { loading: loadingSubscriptionManagement } }
) => ({
  ...state,
  loadingSubscriptionManagement,
});
