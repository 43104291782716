import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import { useFela } from 'react-fela';
import { IStyle } from 'fela';
import { groupBy, maxBy, sortBy } from 'lodash';

import useMapSearch from './use-map-search';
import i18n from '../../../../../i18n';
import { Map } from '../../../types';

type MapSelectProps = {
  className?: string;
  currentMap: Map;
  disabled?: boolean;
  maps?: Map[];
  onChange?: (id: string) => void;
};

export default function MapSelect({
  className = '',
  currentMap,
  disabled = false,
  maps = [],
  onChange = () => {},
}: MapSelectProps) {
  const { t } = useTranslation();
  const { css } = useFela();
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const searchResults = useMapSearch({
    maps,
    search,
  });

  const categorizedSearchResults = sortBy(
    sortBy(groupBy(searchResults, 'item.category'), (_, category) =>
      t(`platform.lobby.options.map.categories.${category}`)
    ),
    (maps) => maxBy(maps, 'score')
  );

  return (
    <ButtonDropdown
      className={`${className} ${css(styles.dropdownButton)}`}
      isOpen={isOpen}
      toggle={() => {
        if (isOpen) setSearch('');

        setIsOpen(!isOpen);
      }}
    >
      <DropdownToggle caret outline>
        {currentMap.name}
        {renderTags(currentMap.tags)}
      </DropdownToggle>
      <DropdownMenu className={css(styles.menu)}>
        <Form className='px-2' tag='div'>
          <FormGroup>
            <Input
              autoFocus
              placeholder={t('platform.lobby.options.map.search')}
              value={search}
              onChange={({ currentTarget: { value } }) => setSearch(value)}
            />
          </FormGroup>
        </Form>
        <div className={css(styles.itemsContainer)}>
          {categorizedSearchResults.flatMap((maps) => {
            const category = maps[0].item.category;

            return [
              <DropdownItem
                header
                className={css(styles.categoryItem)}
                key={category}
              >
                {t(`platform.lobby.options.map.categories.${category}`)}
              </DropdownItem>,
              maps.map(({ item: { id, name, tags } }, index) => {
                const active = id === currentMap.id;
                const activeBackground = (active && 'bg-secondary') || '';
                const disabledActiveText =
                  (disabled && active && 'text-white') || '';
                const className = `${activeBackground} ${disabledActiveText}`;

                return (
                  <DropdownItem
                    active={active}
                    className={className}
                    disabled={disabled}
                    key={id}
                    onClick={() => onChange(id)}
                    tabIndex={index}
                  >
                    {name}
                    {renderTags(tags)}
                  </DropdownItem>
                );
              }),
            ];
          })}
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

const styles: Record<string, IStyle> = {
  dropdownButton: {
    display: 'block',
  },
  menu: {
    width: '250px',
  },
  itemsContainer: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
  categoryItem: {
    color: '#9a2f2f',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
  },
};

const renderTags = (tags: Map['tags']) =>
  tags.map((tag) => {
    const color = typeof tag === 'string' ? undefined : tag.color;
    const name = typeof tag === 'string' ? tag : tag.tag;

    const text = i18n.t(`platform.lobby.options.map.tags.${name}`);

    return (
      <Badge className='mx-1' color={color} key={name}>
        {text}
      </Badge>
    );
  });
