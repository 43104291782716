import React from 'react';
import { useFela } from 'react-fela';
import { IStyle } from 'fela';

type UserAvatarProps = {
  id?: string;
  className?: string;
  imageUrl: string;
  name?: string;
};

export default function UserAvatar({
  id = '',
  className = '',
  imageUrl,
  name = '',
}: UserAvatarProps) {
  const { css } = useFela();

  const style: IStyle = {
    borderRadius: '.4em',
    maxHeight: '1.5em',
    margin: '0 .15em',
  };

  return (
    <img
      id={id}
      className={`${className} ${css(style)}`}
      alt={name}
      src={imageUrl}
    />
  );
}
