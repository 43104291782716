import { put } from 'redux-saga/effects';
import decode from 'jwt-decode';

import { setToken, setTokenData } from '../actions';

export default function* ({ token }) {
  yield put(setToken({ token }));
  const tokenData = decode(token);
  yield put(setTokenData({ tokenData }));
}
