import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { SIGN_OUT } from '../actionTypes';
import { clearData } from '../../../../authentication/store/actions';

const LANDING_PAGE_PATH = '/';

export default function* signOutWatcher() {
  yield takeEvery(SIGN_OUT, signOut);
}

export function* signOut() {
  yield put(clearData());
  yield put(push(LANDING_PAGE_PATH));
}
