import { call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  setChangingTeam,
  setData,
  changeTeam as changeTeamAction,
} from '../slice';
import { getLobbyId } from '../selectors';
import { getUserId } from '../../../../authentication/store/selectors';
import { updateLobbyChangeTeam } from '../../../../apis/server';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import i18n from '../../../../i18n';
import { Team } from '../../types';
import { mapToApiTeam } from '../../presenters/team';

export default function* changeTeam(teamId: Team['id']) {
  const matchId: string | undefined = yield select(getLobbyId);
  const userId: string | undefined = yield select(getUserId);

  if (!userId) return;

  yield put(setChangingTeam(true));

  try {
    const { data } = yield call(consistentlyRequest, updateLobbyChangeTeam, {
      matchId,
      teamId: mapToApiTeam(teamId),
      userId,
    });

    yield put(setData(data));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.changeTeam'));
  } finally {
    yield put(setChangingTeam(false));
  }
}

export function* watchChangeTeam() {
  yield takeEvery(changeTeamAction.toString(), function* ({
    payload: { id },
  }: ReturnType<typeof changeTeamAction>) {
    yield call(changeTeam, id);
  });
}
