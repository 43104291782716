import { call, put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { getRedirectPath } from '../selectors';
import { clearRedirectPath } from '../actions';
import persistState from '../../../store/meta/sagas/persistState';

export const DEFAULT_REDIRECT_PATH = '/home';

export default function* redirectAfterAuth() {
  const stateRedirectPath = yield select(getRedirectPath);

  const redirectPath = stateRedirectPath || DEFAULT_REDIRECT_PATH;

  yield put(clearRedirectPath());
  yield call(persistState);
  yield put(replace(redirectPath));
}
