import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Lottie from 'lottie-react-web';

import Leaderboard from './leaderboard';
import errorAnimation from '../../../assets/error-animation-data.json';
import { useTranslation } from 'react-i18next';

export default function SafeLeaderboard() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Leaderboard />
    </ErrorBoundary>
  );
}

function ErrorFallback() {
  const { t } = useTranslation();

  return (
    <div className='d-flex flex-column align-items-center justify-content-start'>
      <Lottie options={{ animationData: errorAnimation }} />
      <span>{t('leaderboard.broken')}</span>
    </div>
  );
}
