import { get } from 'lodash';

import { getUserData } from '../../../../authentication/store/selectors';

export const getUserMembership = (state) =>
  get(getUserData(state), 'membership');

export const getUserMembershipTier = (state) =>
  get(getUserMembership(state), 'tier', 'free').toString().toLowerCase();

export const userHasMembership = (state) => !!getUserMembership(state);
