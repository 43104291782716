import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM_URL;

export default function InstagramInfo({ className = '', noText = false }) {
  const { t } = useTranslation();

  return (
    <a className={className} href={INSTAGRAM_URL} target="_blank">
      <FontAwesomeIcon icon={faInstagram} />{' '}
      {!noText && t('footer.instagramContact')}
    </a>
  );
}
