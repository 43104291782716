import React from 'react';

export type AvatarProps = {
  alt: string;
  className?: string;
  circle?: boolean;
  url: string;
};

export default function Avatar({
  alt,
  className = '',
  circle = true,
  url,
}: AvatarProps) {
  const styleClass = circle ? 'rounded-circle' : '';
  const classNames = `${className} ${styleClass}`;

  return <img className={classNames} src={url} alt={alt} />;
}
