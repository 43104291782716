import { SET_PERSISTOR, PERSIST_STATE, STATE_PERSISTED } from './actionTypes';

export const persistState = () => ({
  type: PERSIST_STATE,
});

export const setPersistor = ({ persistor }) => ({
  type: SET_PERSISTOR,
  payload: {
    persistor,
  },
});

export const statePersisted = () => ({
  type: STATE_PERSISTED,
});
