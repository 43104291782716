import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';

import { store } from './store';
import serverApi from './apis/server/axios';
import membershipApi from './apis/membership/api';
import leaderboardApi from './apis/leaderboard/axios';
import setupAxiosAuthProvider from './utils/axiosAuthProvider';

import App from './App';

LogRocket.init('c4h28o/quickserver');

setupAxiosAuthProvider(serverApi, store);
setupAxiosAuthProvider(membershipApi, store);
setupAxiosAuthProvider(leaderboardApi, store);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
