import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export type LeaderboardInfoIconProps = {
  className?: string;
};

export default function LeaderboardInfoIcon({
  className = '',
}: LeaderboardInfoIconProps) {
  const { t } = useTranslation();
  const [id] = useState(() => `leaderboard-info-icon-${uuid()}`);

  return (
    <>
      <FontAwesomeIcon className={className} id={id} icon={faInfoCircle} />
      <UncontrolledPopover trigger='hover' target={id} placement='right'>
        <PopoverHeader>{t('leaderboard.infoPopover.header')}</PopoverHeader>
        <PopoverBody>{t('leaderboard.infoPopover.body')}</PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
