import {
  faArrowLeft,
  faArrowRight,
  faBan,
  faMeteor,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { IStyle } from 'fela';
import React from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import ActionIcon from '../action-icon';

import UserAvatar from './user-avatar';

type UserItemProps = {
  admin?: boolean;
  avatarUrl: string;
  movable?: boolean;
  name: string;
  onMove?: (teamId: 'left' | 'right') => void;
  onRemove?: () => void;
  removable?: boolean;
  tier?: 'free' | 'gold';
  userId: string;
};

const TIER_ICONS = {
  free: false,
  gold: faMeteor,
};

export default function UserItem({
  admin = false,
  avatarUrl,
  movable = false,
  name = '',
  onMove = () => {},
  onRemove = () => {},
  removable = false,
  tier = 'free',
  userId,
}: UserItemProps) {
  const { css } = useFela();
  const { t } = useTranslation();

  const tierIcon = TIER_ICONS[tier];
  const targetId = `spec-user-${userId}`;
  const interactible = removable || movable;

  return (
    <div>
      <UserAvatar id={targetId} imageUrl={avatarUrl} name={name} />
      <UncontrolledPopover trigger='hover' placement='top' target={targetId}>
        <PopoverHeader>
          {name}{' '}
          {tierIcon && (
            <ActionIcon
              className={css(styles.item, styles.actionIcon)}
              icon={tierIcon}
              tooltipText={t(`platform.lobby.team.user.tiers.${tier}`)}
            />
          )}
          {admin && (
            <ActionIcon
              className={css(styles.item, styles.actionIcon)}
              icon={faStar}
              tooltipText={t('platform.lobby.team.user.admin')}
            />
          )}
        </PopoverHeader>
        {interactible && (
          <PopoverBody>
            <div className='d-flex flex-row justify-content-center align-items-center'>
              {removable && (
                <ActionIcon
                  className={css(styles.item, styles.actionIcon)}
                  clickable
                  icon={faBan}
                  onClick={onRemove}
                  tooltipText={t('platform.lobby.team.user.remove')}
                />
              )}
              {movable && (
                <>
                  <ActionIcon
                    className={css(styles.item, styles.actionIcon)}
                    clickable
                    icon={faArrowLeft}
                    onClick={() => onMove('left')}
                    tooltipText={t('platform.lobby.team.user.swap')}
                  />
                  <ActionIcon
                    className={css(styles.item, styles.actionIcon)}
                    clickable
                    icon={faArrowRight}
                    onClick={() => onMove('right')}
                    tooltipText={t('platform.lobby.team.user.swap')}
                  />
                </>
              )}
            </div>
          </PopoverBody>
        )}
      </UncontrolledPopover>
    </div>
  );
}

const styles: Record<string, IStyle> = {
  item: {
    margin: '0 .2em',
  },
  actionIcon: {
    color: '#aaa',
  },
};
