import React, { useEffect, useState } from 'react';
import {
  Row,
  Spinner,
  Button,
  Input,
  FormGroup,
  Label,
  Col,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';

import DiscordContactLink from '../../../../components/DiscordContactLink';
import PartnerEmbed from './partner-embed';

import notificationsDemo from '../../../../assets/notifications-demo.webm';
import usePartnerApi from './use-partner-api';

const TAKING_TOO_LONG_TIMEOUT = 4 * 60 * 1000;

type LoadingProps = {
  abortable?: boolean;
  aborting?: boolean;
  onAbort?: () => void;
  onNotifyOnReadyChange?: (notifyOnReady: boolean) => void;
  notifyOnReady?: boolean;
  showStreamerPartner?: boolean;
  lobbyTier?: 'free' | 'gold';
  goldUserNames?: string[];
};

export default function Loading({
  abortable = false,
  aborting = false,
  onAbort = () => {},
  onNotifyOnReadyChange = () => {},
  notifyOnReady = false,
  showStreamerPartner = false,
  lobbyTier = 'free',
  goldUserNames = [],
}: LoadingProps) {
  const { t } = useTranslation();
  const [showDiscord, setShowDiscord] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowDiscord(true);
    }, TAKING_TOO_LONG_TIMEOUT);
  }, []);

  const abortText = aborting
    ? 'platform.lobby.modal.connection.aborting'
    : 'platform.lobby.modal.connection.abort';

  return (
    <div className='p-4 m-2'>
      <Row className='my-1'>
        <Col>
          <div className='d-flex flex-row align-items-baseline'>
            <Spinner size='sm' className='mr-2' style={{ margin: '-1.5em' }} />
            <h5>{t('platform.lobby.modal.loading.header')}</h5>
            {(lobbyTier === 'free' && (
              <span className='text-muted mx-1'>
                <Trans
                  t={t}
                  i18nKey='platform.lobby.modal.loading.usualTime.freeTagline'
                  components={{
                    goldlink: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        target='_blank'
                        id='usual-time-popover-trigger'
                        href='/gold'
                      />
                    ),
                  }}
                />
              </span>
            )) ||
              (lobbyTier === 'gold' && (
                <>
                  <span
                    id='usual-time-popover-trigger'
                    className='text-muted mx-1'
                  >
                    {t('platform.lobby.modal.loading.usualTime.goldTagline')}
                  </span>
                </>
              ))}
            <UncontrolledPopover
              trigger='hover'
              target='usual-time-popover-trigger'
              placement='top'
            >
              <PopoverBody>
                <p>
                  {t(
                    `platform.lobby.modal.loading.usualTime.${lobbyTier}PopoverBody`
                  )}
                </p>
                {lobbyTier === 'gold' && (
                  <ul>
                    {goldUserNames.map((userName) => (
                      <li>{userName}</li>
                    ))}
                  </ul>
                )}
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </Col>
      </Row>
      <Row className='my-1'>
        <Col>
          <FormGroup check>
            <Label check id='enable-notifications-checkbox'>
              <Input
                type='checkbox'
                onChange={() => onNotifyOnReadyChange(!notifyOnReady)}
                checked={notifyOnReady}
                value='on'
              />{' '}
              {t('platform.lobby.modal.loading.notifyOnReady')}
            </Label>
          </FormGroup>
          <UncontrolledPopover
            target='enable-notifications-checkbox'
            trigger='hover'
            placement='top'
            className='bs-wider-popover'
          >
            <PopoverHeader>
              {t('platform.lobby.modal.loading.notifyOnReadyPopover.title')}
            </PopoverHeader>
            <PopoverBody>
              <div style={{ maxWidth: 320 }}>
                <video width={320} height={200} autoPlay loop>
                  <source src={notificationsDemo} type='video/webm' />
                </video>
                <p>
                  {t(
                    'platform.lobby.modal.loading.notifyOnReadyPopover.explanation'
                  )}
                </p>
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </Col>
        {abortable && (
          <Col sm='auto'>
            <Button onClick={onAbort} color='danger' outline size='sm'>
              {aborting && <Spinner size='sm' />} {t(abortText)}
            </Button>
          </Col>
        )}
      </Row>
      {showDiscord && (
        <Row className='justify-content-center mt-4'>
          <p>
            {t('platform.lobby.modal.loading.takingTooLong')}{' '}
            <DiscordContactLink className='text-secondary' />
          </p>
        </Row>
      )}
      {showStreamerPartner && (
        <>
          <hr />
          <PartnerSection />
        </>
      )}
    </div>
  );
}

function PartnerSection() {
  const { t } = useTranslation();
  const { loading, partner } = usePartnerApi();

  if (loading || !partner) {
    return (
      <Row className='justify-content-center '>
        <Col xs='12'>
          <p>
            <Trans
              t={t}
              components={{
                em: <em />,
              }}
            >
              platform.lobby.modal.loading.streamerPartners.loadingPartnerContent
            </Trans>
          </p>
        </Col>
        <Col xs='12'>
          <ContentLoader
            width='100%'
            height='360px'
            speed={2}
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='2' ry='2' width='100%' height='100%' />
          </ContentLoader>
        </Col>
      </Row>
    );
  }

  return (
    <Row className='justify-content-center '>
      <Col xs='12'>
        <p>
          {t('platform.lobby.modal.loading.streamerPartners.whileYouWait', {
            context: partner?.gender,
            name: partner?.name,
          })}
        </p>
      </Col>
      <Col xs='12'>
        <PartnerEmbed url={partner?.url || ''} />
      </Col>
    </Row>
  );
}
