import { eventChannel, END } from 'redux-saga';

import { closed, error, messageReceived, opened } from './actions';
import { getConnectionUrl } from './helper';

export type EventType =
  | ReturnType<typeof messageReceived>
  | ReturnType<typeof opened>
  | ReturnType<typeof closed>
  | ReturnType<typeof error>;

export default ({ token, matchId }: { token: string; matchId: string }) =>
  eventChannel<EventType>((emitter) => {
    const connectionUrl = getConnectionUrl({ token });
    const socket = new WebSocket(connectionUrl);

    socket.onopen = () => {
      socket.send(
        JSON.stringify({ action: 'SUBSCRIBE', resource: 'MATCH', id: matchId })
      );

      setInterval(() => {
        socket.send(JSON.stringify({ action: 'PING' }));
      }, 9 * 60 * 1000);

      emitter(opened());
    };

    socket.onclose = () => {
      emitter(closed());
      emitter(END);
    };

    socket.onmessage = ({ data }) => {
      const message = JSON.parse(data);
      emitter(messageReceived({ message }));
    };

    socket.onerror = () => {
      emitter(error());
    };

    return () => {
      socket.close(1000, 'User connection terminated.');
    };
  });
