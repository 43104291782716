import { call, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import { MANAGE_SUBSCRIPTION } from '../actionTypes';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import { getUserMembershipTier } from '../selectors/user';
import { createCustomerPortalSession } from '../../../../apis/membership';
import i18n from '../../../../i18n';
import { setLoadingSubscriptionManagement } from '../actions';

const MEMBERSHIP_PAGE = '/membership';

export default function* manageSubscription() {
  const currentSubscription = yield select(getUserMembershipTier);

  if (currentSubscription === 'free') {
    yield put(push(MEMBERSHIP_PAGE));
    return;
  }

  yield put(setLoadingSubscriptionManagement({ loading: true }));
  try {
    const { data: session } = yield call(
      consistentlyRequest,
      createCustomerPortalSession
    );
    window.location.href = session.url;
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('membership.errors.subscriptionRedirectionError'));
  }
  yield put(setLoadingSubscriptionManagement({ loading: false }));
}

export function* watch() {
  yield takeEvery(MANAGE_SUBSCRIPTION, manageSubscription);
}
