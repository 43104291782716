import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import { joinFromCodeInput as joinFromCodeInputAction } from '../slice';
import i18n from '../../../../i18n';

const CODE_EXTRACTION_REGEX = /\/?([A-Z0-9]{4})$/;

export default function* joinFromCodeInput(codeInput: string) {
  try {
    const code = codeInput.match(CODE_EXTRACTION_REGEX)![1];

    if (isEmpty(code))
      throw new Error(`Could not find lobby id. (input: ${codeInput})`);

    yield put(push(`/${code}`));
  } catch (error) {
    console.error(error);

    toast.error(i18n.t('platform.home.errors.sagas.invalidLobbyCode'));
  }
}

export function* watchJoinFromCodeInput() {
  yield takeEvery(joinFromCodeInputAction.toString(), function* ({
    payload: { code },
  }: ReturnType<typeof joinFromCodeInputAction>) {
    yield call(joinFromCodeInput, code);
  });
}
