import { replace } from 'connected-react-router';
import { call, delay, put, takeEvery } from 'redux-saga/effects';

import fetchUserData from '../../../../authentication/store/sagas/fetchUserData';
import { WELCOME_PAGE_LOAD } from '../actionTypes';

const HOME_PATH = '/home';

export default function* welcomePageLoad() {
  yield delay(5000);
  yield call(fetchUserData);
  yield delay(2000);
  yield put(replace(HOME_PATH));
}

export function* watch() {
  yield takeEvery(WELCOME_PAGE_LOAD, welcomePageLoad);
}
