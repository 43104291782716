import Lottie from 'lottie-react-web';
import React from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';

import animationData from '../../../assets/404-animation-data.json';

export default function EmptyState({ className = '' }: { className?: string }) {
  const { css } = useFela();
  const { t } = useTranslation();

  return (
    <div className={`${css(style.container)} ${className}`}>
      <div className={css(style.animationContainer)}>
        <Lottie options={{ animationData, loop: true, autoplay: true }} />
      </div>
      <span className={css(style.notFound)}>
        {t('history.emptyState.matchNotFound')}
      </span>
      <p className={`text-muted text-center ${css(style.solveIt)}`}>
        {t('history.emptyState.solveItNow')}
      </p>
    </div>
  );
}

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  animationContainer: {
    opacity: '80%',
    margin: '1em',
  },
  notFound: {
    textAlign: 'center',
    fontFamily: 'Bebas Neue',
    fontSize: '1.3em',
  },
  solveIt: {
    margin: '0.6em 0',
  },
} as const;
