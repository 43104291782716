import React, { useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  Spinner,
} from 'reactstrap';
import Popover from 'react-bootstrap/Popover';
import { uniqueId } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';

import ActionIcon from '../action-icon';
import validateName from './team-name.validations';
import { useRef } from 'react';
import Overlay from 'react-bootstrap/esm/Overlay';

export type TeamNameProps = {
  className?: string;
  editable?: boolean;
  name: string;
  onChange?: (name: string) => void;
  reverse?: boolean;
  loading?: boolean;
};

export default function TeamName({
  className = '',
  editable = false,
  name,
  onChange = () => {},
  reverse = false,
  loading = false,
}: TeamNameProps) {
  const { css } = useFela({ reverse });
  const { t } = useTranslation();
  const target = useRef<HTMLDivElement>(null);

  const [isChangingName, setIsChangingName] = useState(false);
  const [temporaryName, setTemporaryName] = useState(name);

  const [popoverId] = useState(() => uniqueId());
  const validationResult = useMemo(() => validateName(temporaryName), [
    temporaryName,
  ]);
  const nameValid = validationResult.successful;

  useEffect(() => {
    setTemporaryName(name);
  }, [name]);

  const startChange = () => {
    setIsChangingName(true);
  };
  const commitChange = () => {
    if (!nameValid) return;

    onChange(temporaryName);
    setIsChangingName(false);
  };
  const cancelChange = () => {
    setTemporaryName(name);
    setIsChangingName(false);
  };

  return (
    <div
      className={`${className} ${css(({ reverse }) => ({
        display: 'flex',
        flexDirection: reverse ? 'row-reverse' : 'row',
        alignItems: 'center',
      }))}`}
    >
      <div ref={target}>
        {isChangingName ? (
          <div>
            <InputGroup>
              <Input
                className={css({ display: 'inline' })}
                autoFocus
                value={temporaryName}
                onChange={({ currentTarget: { value } }) =>
                  setTemporaryName(value)
                }
                onKeyPress={({ key }) => {
                  if (key === 'Escape') return cancelChange();

                  if (key === 'Enter') return commitChange();
                }}
              />
              <InputGroupAddon addonType='append'>
                <Button
                  color='success'
                  onClick={commitChange}
                  disabled={!nameValid}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </InputGroupAddon>
              <InputGroupAddon addonType='append'>
                <Button color='danger' onClick={cancelChange}>
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
        ) : (
          <div>
            <span
              className={`h1 h1-fs heading-font ${css({ display: 'inline' })}`}
            >
              {name}
            </span>
            {editable && !loading && (
              <ActionIcon
                className={css({
                  margin: '.5em',
                })}
                icon={faEdit}
                tooltipText={t('platform.lobby.team.names.change')}
                color='secondary'
                onClick={startChange}
              />
            )}
          </div>
        )}
      </div>
      {loading && (
        <Spinner
          className={css({
            alignSelf: 'center',
            margin: '.5em',
          })}
          size='sm'
        />
      )}
      <Overlay
        target={target.current}
        show={isChangingName && !nameValid}
        placement='top'
      >
        <Popover id={`change-team-popover-${popoverId}`}>
          <Popover.Title>
            {t('platform.lobby.team.names.validations.teamNameHeader')}
          </Popover.Title>
          <Popover.Content>
            <ul className='list-unstyled'>
              {validationResult.validations.map(
                ({ meta, successful, type }, index) => (
                  <li
                    key={index}
                    className={successful ? 'text-success' : 'text-danger'}
                  >
                    <FontAwesomeIcon
                      style={{ width: '1em' }}
                      icon={successful ? faCheck : faTimes}
                    />{' '}
                    <span>
                      {t(`platform.lobby.team.names.validations.${type}`, meta)}
                    </span>
                  </li>
                )
              )}
            </ul>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
}
