import React from 'react';

import Avatar from './avatar';

export type UsersProps = {
  users: Array<User>;
};

export type User = {
  id: string;
  avatar: string;
  name: string;
};

export default function Users({ users }: UsersProps) {
  return (
    <>
      {users.map(({ id, avatar, name }) => (
        <Avatar key={id} image={avatar} name={name} />
      ))}
    </>
  );
}
