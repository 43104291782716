import React from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  Button,
  Spinner,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import MatchOptions from './match-options';
import Team from './team';
import Actions from './actions';
import Spectators from './spectators';

import { Team as TeamType, Map } from '../types';

type RoomProps = {
  availableMaps: Map[];
  changingMap?: boolean;
  changingPlayerTeamChangesBlocked?: boolean;
  changingTeams?: boolean;
  className?: string;
  currentMap: Map;
  finished?: boolean;
  finishing?: boolean;
  leaving?: boolean;
  onChangeUserTeam?: (userId: string, teamId: TeamType['id']) => void;
  onJoinTeam?: (teamId: TeamType['id']) => void;
  onLeave?: () => void;
  onMapChange?: (mapId: string) => void;
  onRemoveUser?: (userId: string) => void;
  onStart?: () => void;
  onTeamNameChange?: (teamId: TeamType['id'], name: string) => void;
  onPlayerTeamChangesBlockedChange: (blocked: boolean) => void;
  optionsDisabled?: boolean;
  playerTeamChangesBlocked?: boolean;
  spectatable: boolean;
  starting?: boolean;
  teams: {
    left: TeamType;
    right: TeamType;
    spec: TeamType;
  };
  unstartable?: boolean;
  unstartableReasons?: string[];
  url: string;
};

export default function Room({
  availableMaps,
  changingMap = false,
  changingPlayerTeamChangesBlocked = false,
  changingTeams = false,
  className = '',
  currentMap,
  finished,
  finishing,
  leaving = false,
  onChangeUserTeam = (_id) => {},
  onJoinTeam = (_id) => {},
  onLeave = () => {},
  onMapChange = (_map) => {},
  onPlayerTeamChangesBlockedChange = () => {},
  onRemoveUser = (_id) => {},
  onStart = () => {},
  onTeamNameChange = () => {},
  optionsDisabled = false,
  playerTeamChangesBlocked = false,
  spectatable = false,
  starting = false,
  teams,
  unstartable = false,
  unstartableReasons = [],
  url,
}: RoomProps) {
  const { t } = useTranslation();

  const { left: leftTeam, right: rightTeam, spec } = teams;
  const shouldShowUnstartableAlert =
    unstartable && !isEmpty(unstartableReasons);

  if (finished || finishing) return null;

  const renderTeam = ({
    id,
    joinable,
    name,
    reverse,
    users,
    onChangeTeam,
    nameEditable,
    nameLoading,
  }: TeamType & { onChangeTeam: (id: string) => void }) => (
    <Team
      className='my-4 my-md-0'
      joinable={joinable}
      joining={changingTeams}
      name={name}
      nameEditable={nameEditable}
      nameLoading={nameLoading}
      onJoin={() => onJoinTeam(id)}
      onRemoveUser={onRemoveUser}
      onSwapUser={onChangeTeam}
      onSpecUser={(id) => onChangeUserTeam(id, 'spec')}
      onNameChange={(name) => onTeamNameChange(id, name)}
      users={users}
      reverse={reverse}
    />
  );

  return (
    <Container className={className}>
      <Row className='justify-content-center my-4'>
        <MatchOptions
          changingMap={changingMap}
          currentMap={currentMap}
          disabled={optionsDisabled}
          lobbyUrl={url}
          maps={availableMaps}
          onMapChange={onMapChange}
          changingPlayerTeamChangesBlocked={changingPlayerTeamChangesBlocked}
          playerTeamChangesBlocked={playerTeamChangesBlocked}
          onPlayerTeamChangesBlockedChange={onPlayerTeamChangesBlockedChange}
        />
      </Row>
      <Row className='justify-content-center my-4'>
        <Col md='6' xl='5'>
          {renderTeam({
            ...leftTeam,
            reverse: false,
            onChangeTeam: (id) => onChangeUserTeam(id, 'right'),
          })}
        </Col>
        <Col md='6' xl='5'>
          {renderTeam({
            ...rightTeam,
            reverse: true,
            onChangeTeam: (id) => onChangeUserTeam(id, 'left'),
          })}
        </Col>
      </Row>
      <Row className='justify-content-center my-4'>
        <Col xs='0'>
          <div className='d-flex flex-row align-items-center'>
            <div className='m-2' style={{ color: 'gray' }}>
              <Button
                color='link'
                disabled={changingTeams || !spectatable}
                onClick={() => onJoinTeam('spec')}
                id='spectators-help-target'
                style={{ color: 'gray' }}
              >
                {changingTeams && <Spinner size='sm' />}{' '}
                <FontAwesomeIcon icon={faSignInAlt} />
              </Button>{' '}
              <span className='h6'>{t('platform.lobby.bleachersLabel')}</span>
            </div>
            <UncontrolledPopover
              target='spectators-help-target'
              trigger='hover'
              placement='top'
            >
              <PopoverHeader>
                {t('platform.lobby.bleachersHelpPopover.header')}
              </PopoverHeader>
              <PopoverBody>
                {t('platform.lobby.bleachersHelpPopover.body')}
              </PopoverBody>
            </UncontrolledPopover>
            <Spectators
              users={spec.users}
              onRemove={onRemoveUser}
              onMove={onChangeUserTeam}
            />
          </div>
        </Col>
      </Row>
      {shouldShowUnstartableAlert && (
        <Row className='justify-content-center'>
          <Alert color='warning'>
            <ul className='mb-0 ml-0 pl-4'>
              {unstartableReasons.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ul>
          </Alert>
        </Row>
      )}
      <Row className='justify-content-center my-4'>
        <Actions
          leaving={leaving}
          onLeave={onLeave}
          onStart={onStart}
          starting={starting}
          unstartable={unstartable}
        />
      </Row>
    </Container>
  );
}
