import { call, takeEvery } from 'redux-saga/effects';

import { LOAD } from '../actionTypes';
import fetchOngoingLobbies from './fetchOngoingLobbies';

export default function* load() {
  yield call(fetchOngoingLobbies);
}

export function* watchLoad() {
  yield takeEvery(LOAD, load);
}
