import api from './axios';
import { Lobby, PlayerTeam, User } from './types';

export const getMatchesByUserId = () =>
  api.get<Lobby[]>('match/by_user_id', {});

export const createLobby = () => api.post<Lobby>('lobby/create', {});

export const joinLobby = ({ id }: { id: string }) =>
  api.post<Lobby>('lobby/join', { lobbyId: id });

export const updateLobbyQuit = ({ matchId }: { matchId: string }) =>
  api.post<Lobby>('lobby/quit', { matchId });

export const updateLobbyKick = ({
  matchId,
  userId,
}: {
  matchId: string;
  userId: string;
}) => api.post<Lobby>('lobby/kick', { matchId, userId });

export const updateLobbyChangeMap = ({
  matchId,
  ...params
}: {
  matchId: string;
} & Partial<
  Pick<
    Lobby,
    | 'map'
    | 'mapType'
    | 'isSkinsEnabled'
    | 'isPlayerChangeTeamEnabled'
    | 'team1Name'
    | 'team2Name'
  >
>) => api.post<Lobby>('lobby/change_map', { matchId, ...params });

export const updateLobbyChangeTeam = ({
  matchId,
  userId,
  teamId,
}: {
  matchId: string;
  userId: string;
  teamId: PlayerTeam;
}) => api.post<Lobby>('lobby/change_team', { matchId, userId, teamId });

export const updateMatchStartServer = ({ matchId }: { matchId: string }) =>
  api.post<Lobby>('match/start_server', { matchId });

export const updateMatchAbort = ({ matchId }: { matchId: string }) =>
  api.post<Lobby>('match/abort', { matchId });

export const getMatchById = ({ matchId }: { matchId: string }) =>
  api.get<Lobby>('match/by_id', { params: { matchId } });

export const addRating = ({
  matchId,
  score,
  message = '',
}: {
  matchId: string;
  score: number;
  message: string;
}) => api.post<Lobby>('match/add_rating', { matchId, score, message });

export const getUserById = ({ userId }: { userId: string }) =>
  api.get<User>('user/by_id', { params: { userId } });

export const getHistory = ({
  page = 0,
  pageSize = 10,
}: {
  page?: number;
  pageSize?: number;
} = {}) =>
  api.get<Lobby[]>('match/history_by_user', { params: { page, pageSize } });
