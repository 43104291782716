import { combineReducers } from 'redux';

import { reducer as home } from '../home/store';
import { reducer as lobby } from '../lobby/store';
import { reducer as meta } from '../meta/store';
import { reducer as membership } from '../membership/store';

export default combineReducers({
  home,
  lobby,
  meta,
  membership,
});
