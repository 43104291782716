export const CHECK = 'AUTHENTICATION/CHECK';
export const CLEAR_DATA = 'AUTHENTICATION/CLEAR_DATA';
export const CLEAR_REDIRECT_PATH = 'AUTHENTICATION/CLEAR_REDIRECT_PATH';
export const CLEAR_TOKEN = 'AUTHENTICATION/CLEAR_TOKEN';
export const CLEAR_TOKEN_DATA = 'AUTHENTICATION/CLEAR_TOKEN_DATA';
export const CLEAR_USER_DATA = 'AUTHENTICATION/CLEAR_USER_DATA';
export const FETCH_USER_DATA = 'AUTHENTICATION/FETCH_USER_DATA';
export const SET_REDIRECT_PATH = 'AUTHENTICATION/SET_REDIRECT_PATH';
export const SET_TOKEN = 'AUTHENTICATION/SET_TOKEN';
export const SET_TOKEN_DATA = 'AUTHENTICATION/SET_TOKEN_DATA';
export const SET_USER_DATA = 'AUTHENTICATION/SET_USER_DATA';
export const SET_USER_DATA_LOADING = 'AUTHENTICATION/SET_USER_DATA_LOADING';
export const TOKEN_RECEIVED = 'AUTHENTICATION/TOKEN_RECEIVED';
