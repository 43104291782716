import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { redirectToPlanCheckout } from './store/actions';

import MembershipPlanCard from './components/MembershipPlanCard';
import InfoSection from './components/InfoSection';
import { getUserMembershipTier, getCheckoutLoading } from './store/selectors';
import useUrlCouponInfo from './hooks/useUrlCouponInfo';

export function Page({
  subscriptionLoading = false,
  subscription = 'free',
  onSubscribe = () => {},
}) {
  const { discount, amount: discountAmount } = useUrlCouponInfo();

  const subscriptions = [
    { type: 'free', price: 0 },
    { type: 'gold', price: 15.9 },
  ];

  return (
    <Container className='d-flex flex-column'>
      <Row className='justify-content-center'>
        {subscriptions.map(({ price, type }) => (
          <Col key={type} xs='12' md='6' lg='4' className=''>
            <MembershipPlanCard
              key={type}
              current={subscription === type}
              onSubscribe={() => onSubscribe({ plan: type })}
              price={price}
              type={type}
              loading={subscriptionLoading}
              discount={discount}
              discountAmount={discountAmount}
            />
          </Col>
        ))}
        <Col xs='12' md='6' lg='4' className=''>
          <InfoSection />
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  subscription: getUserMembershipTier(state),
  subscriptionLoading: getCheckoutLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSubscribe: ({ plan }) => dispatch(redirectToPlanCheckout({ plan })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);
