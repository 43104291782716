import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { setFetchingOngoingLobbies, setOngoingLobbies } from '../actions';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import { getMatchesByUserId } from '../../../../apis/server';
import i18n from '../../../../i18n';

export default function* fetchOngoingLobbies() {
  yield put(setFetchingOngoingLobbies({ fetching: true }));

  try {
    const { data } = yield call(consistentlyRequest, getMatchesByUserId);

    yield put(setOngoingLobbies({ lobbies: data }));
  } catch {
    toast.error(i18n.t('platform.home.errors.sagas.fetchOngoingLobbies'));
  } finally {
    yield put(setFetchingOngoingLobbies({ fetching: false }));
  }
}
