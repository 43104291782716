import React, { useEffect } from 'react';
import { Row, Container, Col, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFela } from 'react-fela';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { CreateLobby, JoinLobby, LobbyList, Or } from './components';
import { LobbyListProps } from './components/lobby-list/list';
import { create, joinFromCodeInput } from '../lobby/store/slice';
import { getCreating } from '../lobby/store/selectors';
import { getOngoingLobbies } from './store/selectors/page';
import { load } from './store/actions';
import Leaderboard from '../leaderboard/containers/safe-leaderboard';
import LeaderboardInfoIcon from '../leaderboard/components/leaderboard-info-icon';
import History from '../history/containers/history';

export type PageProps = {
  creatingLobby?: boolean;
  onCreateLobby?: () => void;
  ongoingLobbies?: LobbyListProps['lobbies'];
  onJoinLobby?: () => void;
  onLoad?: () => void;
};

export function Page({
  creatingLobby = false,
  onCreateLobby = () => {},
  ongoingLobbies = [],
  onJoinLobby = () => {},
  onLoad = () => {},
}: PageProps) {
  const { t } = useTranslation();
  const { css } = useFela();

  useEffect(() => {
    onLoad();

    return () => {};
  }, [onLoad]);

  return (
    <Container className='d-flex flex-column'>
      <Row className='py-4'>
        <Col lg='4' className='d-none d-lg-block'>
          <div className='d-flex flex-row align-items-baseline mb-1'>
            <h3 className='d-inline my-0'>{t('leaderboard.heading')}</h3>{' '}
            <LeaderboardInfoIcon className='mx-1 text-info' />
          </div>
          <Leaderboard />
        </Col>
        <Col lg='4' className='d-none d-lg-flex flex-column'>
          <div className='d-flex flex-row align-items-baseline mb-1'>
            <h3 className='d-inline my-0'>{t('history.title')}</h3>{' '}
            {/* <LeaderboardInfoIcon className='mx-1 text-info' /> */}
          </div>
          <SimpleBar className={css(style.historyContainer)}>
            <History />
          </SimpleBar>
        </Col>
        <Col xs='12' lg='4' className='d-flex justify-content-center'>
          <div className='d-flex flex-column align-items-center justify-content-center'>
            {!isEmpty(ongoingLobbies) && (
              <div className='my-5 justify-content-center'>
                <LobbyList lobbies={ongoingLobbies} />
              </div>
            )}
            <div className='my-5 align-items-center justify-content-center'>
              <JoinLobby className='my-4' onJoin={onJoinLobby} />

              <Or className='my-4 text-center' />

              <CreateLobby
                className='my-4'
                creating={creatingLobby}
                onCreate={onCreateLobby}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const style = {
  historyContainer: {
    maxHeight: 'calc(11 * 3 * 1em)',
    paddingRight: '0.5em',
  },
} as const;

const mapStateToProps = (state: any) => ({
  creatingLobby: getCreating(state),
  ongoingLobbies: getOngoingLobbies(state),
});
const mapDispatchToProps = (dispatch: any) => ({
  onCreateLobby: () => dispatch(create()),
  onJoinLobby: (lobbyId: any) => dispatch(joinFromCodeInput({ code: lobbyId })),
  onLoad: () => dispatch(load()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Page as any);
