import React from 'react';
import { useFela } from 'react-fela';

import Avatar, { AvatarProps } from './Avatar';
import Badge, { BadgeProps } from './Badge';

export type BadgedAvatarProps = {
  className?: string;
  badge: BadgeProps['badge'];
} & AvatarProps;

export default function BadgedAvatar({
  badge,
  className = '',
  ...props
}: BadgedAvatarProps) {
  const { css } = useFela();

  return (
    <div className={`${className} ${css(styles.container)}`}>
      <Avatar {...props} />
      <Badge badge={badge} className={css(styles.icon)} />
    </div>
  );
}

const styles = {
  container: {
    display: 'inline',
    position: 'relative',
  },
  icon: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '50%',
    width: '22px',
    height: '22px',
    marginLeft: '-12px',
    bottom: '-8px',
    position: 'absolute',
  },
} as const;
