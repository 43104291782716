import React from 'react';
import {
  Spinner,
  Row,
  Col,
  // Input,
  // Label,
  // UncontrolledPopover,
  // PopoverBody,
  // PopoverHeader,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { Map } from '../../types';

import MapSelect from './map-select';
import CopyInput from '../copy-input';

const URL_PROTOCOL = process.env.REACT_APP_URL_PROTOCOL;

type MatchOptionsProps = {
  changingMap?: boolean;
  className?: string;
  currentMap: Map;
  disabled?: boolean;
  lobbyUrl: string;
  maps?: Map[];
  onMapChange?: (id: string) => void;
  onPlayerTeamChangesBlockedChange?: (blocked: boolean) => void;
  playerTeamChangesBlocked?: boolean;
  changingPlayerTeamChangesBlocked?: boolean;
};

export default function MatchOptions({
  changingMap = false,
  className = '',
  currentMap,
  disabled = false,
  lobbyUrl,
  maps = [],
  playerTeamChangesBlocked = false,
  changingPlayerTeamChangesBlocked = false,
  onPlayerTeamChangesBlockedChange = () => {},
  onMapChange = () => {},
}: MatchOptionsProps) {
  const { t } = useTranslation();

  const processLobbyUrl = (url: string) => `${URL_PROTOCOL}${url}`;

  return (
    <div className={className}>
      <Row>
        <Col sm='12' md='auto'>
          <div className='form-group'>
            <label htmlFor='map-select'>
              {t('platform.lobby.options.map.label')}{' '}
              {changingMap && <Spinner size='sm' />}
            </label>
            <MapSelect
              currentMap={currentMap}
              disabled={disabled || changingMap}
              maps={maps}
              onChange={onMapChange}
            />
          </div>
        </Col>
        <Col sm='12' md='auto'>
          <div className='form-group'>
            <label htmlFor='lobby-url'>
              {t('platform.lobby.options.lobbyUrl.label')}
            </label>
            <CopyInput value={lobbyUrl} processValue={processLobbyUrl} />
          </div>
        </Col>
        {/* <Col sm='12' md='auto'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
            }}
          >
            <div className='form-group' id='allow-team-changes'>
              <Input
                type='checkbox'
                id='allow-team-changes-checkbox'
                disabled={disabled || changingPlayerTeamChangesBlocked}
                checked={playerTeamChangesBlocked}
                onChange={() =>
                  onPlayerTeamChangesBlockedChange(!playerTeamChangesBlocked)
                }
              />
              <Label for='allow-team-changes-checkbox' check>
                {t('platform.lobby.options.blockPlayerTeamChanges.label')}
              </Label>
            </div>
            {changingPlayerTeamChangesBlocked && (
              <Spinner size='sm' style={{ margin: '0.5em' }} />
            )}
          </div>
          <UncontrolledPopover
            target='allow-team-changes'
            trigger='hover'
            placement='top'
          >
            <PopoverHeader>
              {t('platform.lobby.options.blockPlayerTeamChanges.tooltip.title')}
            </PopoverHeader>
            <PopoverBody>
              {t('platform.lobby.options.blockPlayerTeamChanges.tooltip.text')}
            </PopoverBody>
          </UncontrolledPopover>
        </Col> */}
      </Row>
    </div>
  );
}
