import { useEffect } from 'react';

export default <T extends Element>(
  {
    element,
    onScroll,
  }: {
    element: T | undefined | null;
    onScroll: IntersectionObserverCallback;
  },
  deps: any[]
) => {
  useEffect(() => {
    if (!element) return;

    const observer = new IntersectionObserver(onScroll, {
      root: null,
      rootMargin: '0px',
      threshold: 0.25,
    });
    observer.observe(element);

    return () => observer.disconnect();
  }, [element].concat(deps));
};
