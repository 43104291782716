import React from 'react';
import {
  Button,
  Card,
  CardSubtitle,
  CardTitle,
  PopoverBody,
  Spinner,
  UncontrolledPopover,
} from 'reactstrap';
import { useFela } from 'react-fela';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import PopoverTitle from 'react-bootstrap/esm/PopoverTitle';

const calculateEffectivePrice = (price, discount, amount) => {
  if (!discount || amount === 0) return price;

  if (discount === 'percent') return price * (1 - amount);

  return price - amount;
};

export default function PlanCard({
  billingInterval,
  buttonColor,
  buttonText,
  disabled = false,
  discount = false,
  discountAmount = 0,
  loading = false,
  name,
  onSubscribe = () => {},
  perks = [],
  price = 0,
  tagline,
}) {
  const { css } = useFela();

  const originalPrice = price;
  const effectivePrice = calculateEffectivePrice(
    price,
    discount,
    discountAmount
  );
  const showDiscount = originalPrice !== effectivePrice;

  const styles = {
    container: {},
    perkItem: {
      color: '#404040',
    },
  };

  const currencyFormat = (num) =>
    num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  return (
    <Card body className={`${css(styles.container)} my-2`}>
      <CardTitle tag='h5' className=''>
        {name}
      </CardTitle>
      <CardSubtitle tag='p' className='mb-3 text-muted'>
        {tagline}
      </CardSubtitle>
      <span className='my-3'>
        <span className='h5 d-inline-block'>R$</span>{' '}
        <span className='h3 d-inline-block'>
          {showDiscount && <strike>{currencyFormat(originalPrice)}</strike>}{' '}
          {currencyFormat(effectivePrice)}
        </span>
        /{billingInterval}
      </span>
      <Button
        color={buttonColor}
        disabled={true || loading || disabled}
        onClick={onSubscribe}
      >
        {loading && <Spinner size='sm' />} {buttonText}
      </Button>
      <hr />
      <ul className='list-unstyled'>
        {perks.map(({ icon = faCheck, text, type }) => (
          <li key={text} className={`my-2 ${css(styles.perkItem)}`}>
            <FontAwesomeIcon className='mx-2' icon={icon} /> {text}{' '}
            <PerkIcon type={type} />
          </li>
        ))}
      </ul>
    </Card>
  );
}

const ICONS = {
  'user-only': faUser,
  lobby: faUsers,
};

function PerkIcon({ type }) {
  const { i18n } = useTranslation();
  const [perkId] = useState(() => `perk-icon-${uuid()}`);

  const icon = ICONS[type];

  if (!icon) return <></>;

  return (
    <>
      <FontAwesomeIcon icon={icon} className='mx-2' id={perkId} />
      <UncontrolledPopover target={perkId} trigger='hover'>
        <PopoverTitle>
          <Trans
            i18n={i18n}
            i18nKey={`membership.subscriptions.perkPopover.${type}.title`}
            components={{ bold: <strong /> }}
          />
        </PopoverTitle>
        <PopoverBody>
          <Trans
            i18n={i18n}
            i18nKey={`membership.subscriptions.perkPopover.${type}.body`}
            components={{ bold: <strong /> }}
          />
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
