import { DefaultRootState, MapStateToProps } from 'react-redux';

export function overlay<
  TStateProps = {},
  TOwnProps = {},
  State = DefaultRootState
>(
  mapping: {
    [Prop in keyof TStateProps]: (state: State) => TStateProps[Prop];
  }
): MapStateToProps<TStateProps, TOwnProps, State> {
  return (state: State) => {
    const props = {} as TStateProps;
    for (const prop in mapping) {
      props[prop] = mapping[prop](state);
    }
    return props;
  };
}

export default overlay;
