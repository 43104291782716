import * as api from '../../../apis/server';
import { User } from '../types';

import { getUserBadgeInfo } from '../../../utils/badge';

export const isAdmin = (player: api.Player) => player.level === 'ADMIN';

export const getSmallAvatarUrl = (player: api.Player) =>
  player.user.avatar.small;

export const getTier = (player: api.Player): User['tier'] =>
  player.user?.membership?.tier === 'GOLD' ? 'gold' : 'free';

export const getBadgeInfo = ({ user }: api.Player) => getUserBadgeInfo(user);
