import { takeEvery, put } from 'redux-saga/effects';

import { setSubscribed, unsubscribe as unsubscribeAction } from '../slice';

export default function* unsubscribe() {
  yield put(setSubscribed(false));
}

export function* watch() {
  yield takeEvery(unsubscribeAction.toString(), unsubscribe);
}
