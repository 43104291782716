import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export type CreateLobbyProps = {
  className?: string;
  creating?: boolean;
  onCreate?: () => void;
};

export default function CreateLobby({
  className = '',
  creating = false,
  onCreate = () => {},
}: CreateLobbyProps) {
  const { t } = useTranslation();

  const buttonText = creating
    ? 'platform.home.actions.createLobby.creating'
    : 'platform.home.actions.createLobby.create';

  return (
    <div className={`${className} text-center`}>
      <h2>{t('platform.home.actions.createLobby.createLobby')}</h2>
      <div className='d-flex flex-row justify-content-center'>
        <Button color='success' onClick={onCreate} disabled={creating}>
          {creating && <Spinner size='sm' />} {t(buttonText)}
        </Button>
      </div>
    </div>
  );
}
