import React from 'react';
import ContentLoader from 'react-content-loader';
import { useFela } from 'react-fela';
import { Trans, useTranslation } from 'react-i18next';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  PopoverBody,
  PopoverHeader,
  Spinner,
  UncontrolledDropdown,
  UncontrolledPopover,
} from 'reactstrap';

import { StatField } from '../../../apis/leaderboard';
import { UserBadge } from '../../../apis/server';
import UserDisplay from './user-display';

const LOADING_USER_COUNT = 10;

export default function Leaderboard({
  loading = false,
  onChangeSortKey = () => {},
  sortKey,
  sortKeys = [],
  userStats = [],
  playerCount,
}: {
  loading?: boolean;
  onChangeSortKey?: (key: StatField) => void;
  sortKey: StatField;
  sortKeys?: StatField[];
  playerCount?: number;
  userStats?: {
    avatarUrl: string;
    badge?: UserBadge;
    hasBadge: boolean;
    id: string;
    isUser?: boolean;
    name: string;
    stat: number;
    position: number;
  }[];
}) {
  const { t, i18n } = useTranslation();
  const { css } = useFela();

  const fixedPlaces = userStats.every(({ stat }) => Number.isInteger(stat))
    ? 0
    : 1;

  const scoreHasInfoPopover = i18n.exists(
    `leaderboard.sortKeyInfoPopover.${sortKey}`
  );

  return (
    <div className='d-flex flex-column align-items-stretch'>
      <div className='d-inline-flex flex-row justify-content-between align-items-baseline'>
        <b>{t('leaderboard.player')}</b>
        <UncontrolledDropdown size='sm'>
          <DropdownToggle caret className='btn-light' id='leaderboard-sort-btn'>
            {t(`leaderboard.sortKeys.${sortKey}`)}{' '}
            {loading && <Spinner size='sm' />}
          </DropdownToggle>
          <DropdownMenu>
            {sortKeys.map((field) => (
              <DropdownItem
                key={field}
                active={field === sortKey}
                onClick={() => onChangeSortKey(field)}
              >
                {t(`leaderboard.sortKeys.${field}`)}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledPopover
          target='leaderboard-sort-btn'
          trigger='hover'
          disabled={!scoreHasInfoPopover}
        >
          <PopoverHeader>
            <Trans
              i18nKey={`leaderboard.sortKeyInfoPopover.${sortKey}.title`}
              components={{
                small: <small className='text-muted' />,
              }}
            />
          </PopoverHeader>
          <PopoverBody>
            <Trans
              i18nKey={`leaderboard.sortKeyInfoPopover.${sortKey}.description`}
              components={{
                small: <small className='text-muted' />,
              }}
            />
          </PopoverBody>
        </UncontrolledPopover>
      </div>
      <div>
        {loading ? (
          Array.from({ length: LOADING_USER_COUNT }, (_, index) => (
            <div className='d-flex flex-row align-items-center p-2' key={index}>
              <div>
                <ContentLoader
                  width='100%'
                  height='32px'
                  speed={2}
                  backgroundColor='#f3f3f3'
                  foregroundColor='#ecebeb'
                >
                  <rect x='0' y='25%' rx='2' ry='2' width='1em' height='1em' />
                  <rect
                    x='2em'
                    y='0'
                    rx='20'
                    ry='20'
                    width='32px'
                    height='32px'
                  />
                  <rect
                    x='4.5em'
                    y='25%'
                    rx='2'
                    ry='2'
                    width={`${6 + (crapHash(index) % 4)}em`}
                    height='1em'
                  />
                </ContentLoader>
              </div>
              <div className='ml-auto mr-1'>
                <ContentLoader
                  width='2em'
                  height='1em'
                  speed={2}
                  backgroundColor='#f3f3f3'
                  foregroundColor='#ecebeb'
                >
                  <rect x='0' y='0' rx='2' ry='2' width='100%' height='100%' />
                </ContentLoader>
              </div>
            </div>
          ))
        ) : (
          <>
            {userStats.map(
              ({
                id,
                avatarUrl,
                badge,
                hasBadge,
                name,
                stat,
                isUser,
                position,
              }) => (
                <div
                  className={`d-flex flex-row align-items-center p-2 ${
                    isUser && 'bg-light rounded'
                  }`}
                  key={id}
                >
                  <span
                    className={css({
                      minWidth: '1.2em',
                      margin: '0 0.2em',
                      color: 'grey',
                    })}
                  >
                    {position}{' '}
                  </span>
                  <UserDisplay
                    avatarUrl={avatarUrl}
                    badge={badge}
                    hasBadge={hasBadge}
                    name={name}
                  />
                  <span className='ml-auto mr-1'>
                    {stat.toFixed(fixedPlaces)}
                  </span>
                </div>
              )
            )}
            <small className='text-muted p-2'>
              {t('leaderboard.ofXPlayers', { count: playerCount })}
            </small>
          </>
        )}
      </div>
    </div>
  );
}

function crapHash(i: number) {
  return Math.abs(((i * 2654435761) >> i) + i);
}
