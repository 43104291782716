import { SET_PERSISTOR } from './actionTypes';

export const initialState = {
  persistor: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PERSISTOR:
      return setPersistor(state, action);
    default:
      return state;
  }
};

const setPersistor = (state, action) => {
  const {
    payload: { persistor },
  } = action;

  return {
    ...state,
    persistor,
  };
};
