import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';

import { isAuthenticated } from '../store/selectors';
import { check } from '../store/actions';

import Loading from '../../components/Loading';

export function Authenticated({
  isAuthenticated,
  onCheckAuthentication,
  children,
}) {
  const location = useLocation();

  useEffect(() => {
    onCheckAuthentication(location);
  }, [onCheckAuthentication, location]);

  if (!isAuthenticated) return <Loading />;

  return <>{children}</>;
}
Authenticated.propTypes = {
  isAuthenticated: bool,
  onCheckAuthentication: func,
};
Authenticated.defaultProps = {
  isAuthenticated: false,
  onCheckAuthentication: () => {},
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
});
const mapDispatchToProps = (dispatch) => ({
  onCheckAuthentication: (location) => dispatch(check({ location })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
