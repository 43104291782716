import { useMemo, useEffect } from 'react';
import Fuse from 'fuse.js';

import { Map } from '../../../types';

export const DEFAULT_MAP_SEARCH_KEYS = ['name', 'id', 'category'];

export default ({
  maps,
  search,
  keys = DEFAULT_MAP_SEARCH_KEYS,
}: {
  maps: Map[];
  search: string;
  keys?: string[];
}): Fuse.FuseResult<Map>[] => {
  const fuse = useMemo(
    () => new Fuse<Map>([], { keys }),
    [keys]
  );

  useEffect(() => {
    if (!fuse) return;

    fuse.setCollection(maps);
  }, [fuse, maps]);

  const cleanSearch = search.trim();
  const searchResults = fuse.search(cleanSearch);

  if (searchResults.length > 0 || cleanSearch !== '') return searchResults;

  return maps.map((map, index) => ({
    item: map,
    refIndex: index,
    score: 0,
  }));
};
