import availableMaps from '../helpers/available-maps';
import { Map } from '../types';

const UNKNOWN_MAP: Map = {
  category: '?',
  constraints: { playerCount: { max: 5, min: 1 } },
  extra: {},
  id: '?',
  map: '?',
  name: '?',
  tags: [],
};

export const getCurrent = (map: string, mapType: string | undefined) =>
  availableMaps.find(({ map: mapId, extra }) => {
    if (map !== mapId) return false;

    if (!mapType && !extra.mapType) return true;

    return mapType === extra.mapType;
  }) || UNKNOWN_MAP;
