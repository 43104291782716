const SERVER_URL = process.env.REACT_APP_WEBSOCKET_SERVER_URL;

export const getConnectionUrl = ({ token }: { token: string }) => {
  const url = new URL(SERVER_URL!);

  if (token) {
    url.searchParams.set('token', token);
  }

  return url.toString();
};
