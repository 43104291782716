import { find, get } from 'lodash';
import { createSelector } from 'reselect';

import { getData } from './state';
import { getUserId } from '../../../../authentication/store/selectors';

type State = any;

const ADMIN_LEVEL = 'ADMIN';

export const getPlayers = (state: State) => getData(state)?.players || [];
export const isPlayerAdmin = (player: State) =>
  get(player, 'level') === ADMIN_LEVEL;
export const getUserPlayer = createSelector(
  getUserId,
  getPlayers,
  (currentUserId, players) =>
    find(players, (player) => player.user._id === currentUserId)
);
const userIsAdmin = createSelector(getUserPlayer, isPlayerAdmin);

export const getMap = (state: State) => getData(state)?.map;
export const getMapType = (state: State) => getData(state)?.mapType;

export const getOptionsDisabled = (state: State) => !userIsAdmin(state);
export const getPlayerByUserId = (state: State, userId: string) => {
  const players = getPlayers(state);
  return find(players, (player) => player.user._id === userId);
};

export const getTeamNames = (state: State) => ({
  TEAM_1: getData(state)?.team1Name || 'Capitalistas',
  TEAM_2: getData(state)?.team2Name || 'Socialistas',
  SPEC: 'Espectadores',
});

export const getPlayerTeamChangesBlocked = (state: State) =>
  !getData(state)?.isPlayerChangeTeamEnabled;

export const getLobbyTier = (state: State) =>
  getData(state)?.players.some(
    ({ user: { membership } }) => membership?.tier.toLowerCase() === 'gold'
  ) || false
    ? 'gold'
    : 'free';

export const getGoldUserNames = (state: State) =>
  getData(state)
    ?.players.filter(({ user: { membership } }) => membership?.tier === 'GOLD')
    .map(({ user: { nickname } }) => nickname);
