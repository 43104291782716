import { get } from 'lodash';

import { getData, getJoining, getLobby, hasData } from './state';
import { isPlayerAdmin, getUserPlayer } from './lobby';

import {
  getNotificationPermissions,
  getNotifyOnLobbyReady,
} from '../../../../settings/store/selectors';

type State = any; // TODO:

const PROVISIONING_STATUS = 'SERVER_PROVISIONING';
const RUNNING_STATUS = 'GAME_RUNNING';
const FINISHED_STATUS = 'FINISHED';
const FINISHING_STATUS = 'FINISHING';

const DEFAULT_CSGO_PORT = '27015';

const getLobbyStatus = (state: State) => get(getData(state), 'status');
const getServerIp = (state: State) => get(getData(state), 'publicIp');

export const isReady = (state: State) => !!getData(state) && !getJoining(state);

export const getServerInfo = (state: State) => ({
  ipAddress: getServerIp(state),
  port: DEFAULT_CSGO_PORT,
  password: undefined,
});

export const isAbortable = (state: State) =>
  hasData(state) && !isFinished(state) && isPlayerAdmin(getUserPlayer(state));

export const isServerLoading = (state: State) =>
  getLobbyStatus(state) === PROVISIONING_STATUS;

export const isServerRunning = (state: State) =>
  getLobbyStatus(state) === RUNNING_STATUS;

export const isFinishing = (state: State) =>
  getLobbyStatus(state) === FINISHING_STATUS;

export const isFinished = (state: State) =>
  getLobbyStatus(state) === FINISHED_STATUS;

export const shouldAskForRating = (state: State) =>
  hasData(state) &&
  getUserPlayer(state) &&
  (isFinished(state) || isFinishing(state));

export const getRating = (state: State) =>
  getLocalRating(state) || getPlayerRating(state) || EMPTY_RATING;

export const getUpdatingRating = (state: State) =>
  get(getLobby(state), 'updatingRating', false);

const EMPTY_RATING = { score: undefined, message: '' };

export const getPlayerRating = (state: State) =>
  (hasData(state) || undefined) &&
  get(getUserPlayer(state), 'rating', EMPTY_RATING);

export const getLocalRating = (state: State) => getLobby(state).localRating;

export const getNotifyOnReady = (state: State): boolean =>
  getNotifyOnLobbyReady(state) &&
  (getNotificationPermissions(state) || Notification.permission) === 'granted';

export const getDemoPath = (state: State) => get(getData(state), 'demoPath');

export const getDemoUrl = getDemoPath;

const hasDemo = (state: State) => !!getDemoPath(state);

export const getDemoState = (state: State) => {
  if (isFinished(state) && !hasDemo(state)) return 'unavailable';

  if (hasDemo(state)) return 'available';

  if (isFinishing(state) && !hasDemo(state)) return 'loading';

  return 'unavailable';
};
