import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import { setCreating, create as createAction } from '../slice';
import { createLobby } from '../../../../apis/server';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import i18n from '../../../../i18n';

export default function* create() {
  yield put(setCreating(true));

  let lobbyId = undefined;
  try {
    const { data } = yield call(consistentlyRequest, createLobby);
    const { lobbyIdentifier: id } = data;
    lobbyId = id;
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.create'));
  } finally {
    yield put(setCreating(false));
  }

  if (lobbyId !== undefined) {
    yield put(push(`/${lobbyId}`));
  }
}

export function* watchCreate() {
  yield takeEvery(createAction.toString(), create);
}
