import { getToken } from '../authentication/store/selectors';

export default (axios, store) => {
  axios.interceptors.request.use((config) => {
    const state = store.getState();
    const token = getToken(state);

    if (!token) return config;

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });
};
